import * as Sentry from '@sentry/vue'
import * as stripe from '@stripe/stripe-js'

import { gtmEvt } from '@/utils/utils'

export interface ErrorData {
  code: string
  message: string
  extra?: any
}

export interface HandledError extends ErrorData {
  isHandledError: boolean
}

export function blankError(): ErrorData {
  return { code: '', message: '', extra: {} }
}

export function clearError(error: ErrorData) {
  error.code = ''
  error.message = ''
  error.extra = {}
}

export function setError(dest: ErrorData, src: ErrorData) {
  dest.code = src.code
  dest.message = src.message
  dest.extra = src.extra || {}
}

export function isErrorData(obj: any): obj is ErrorData {
  return !!obj.code && !!obj.message
}

export function enhanceError(error, errorData: ErrorData): HandledError {
  if (error.code) {
    error.original_code = error.code
  }
  if (error.message) {
    error.original_message = error.message
  }
  if (error.extra) {
    error.original_extra = error.extra
  }
  error.code = errorData.code
  error.message = errorData.message
  error.extra = errorData.extra
  error.isHandledError = true
  console.log(`Error: ${error.code} - ${error.message}`)
  gtmEvt(`error_${error.code}`)
  return error
}

export function enhanceStripeError(error: stripe.StripeError): HandledError {
  let errorData = blankError()
  if (!!error.code && error.type == 'card_error') {
    errorData.code = error.code
    if (error.code === 'card_declined') {
      if (error.decline_code === 'insufficient_funds' || error.decline_code === 'generic_decline') {
        errorData.message =
          'There was a problem processing your payment. Please use another method.'
      } else {
        errorData.message =
          'There was a problem processing your payment. Please use another method.'
      }
    } else if (error.code === 'expired_card') {
      errorData.message =
        'Your card has expired. Check the expiration date or use a different card.'
    } else if (error.code === 'incorrect_cvc' || error.code === 'incorrect_number') {
      errorData.message =
        'There was a problem with your payment method. Please check the information you provided.'
    } else if (error.code === 'processing_error') {
      errorData.message =
        'An error occurred while processing the payment. Please try again later or use a different payment method.'
    } else {
      errorData.message =
        'There was a problem processing your payment. Please try again or use a different method.'
    }
  } else {
    Sentry.captureException(
      new Error(`Failed Checkout with Stripe error ${error.type}`, { cause: error }),
    )
    errorData.code = error.type
    errorData.message = 'An error occurred while processing the payment. Please try again.'
  }
  return enhanceError(error, errorData)
}

export function isHandledError(obj: any): obj is HandledError {
  return obj.isHandledError && isErrorData(obj)
}

export function displayHandled(error, errorDisplay: ErrorData) {
  if (isHandledError(error)) {
    setError(errorDisplay, error)
    return
  }
  setError(errorDisplay, { code: 'UNKNOWN', message: 'An error occured processing your request' })
  throw error
}

export function setIfHandled(errorDisplay: ErrorData) {
  return error => displayHandled(error, errorDisplay)
}
