<template>
  <section :class="{ 'flex flex-col': !isCheckoutDesktopFlow }">
    <StatusBar v-if="!isCheckoutDesktopFlow" />
    <section class="px-4" :class="{ 'flex flex-col h-full grow': !isCheckoutDesktopFlow }">
      <h1 class="text-2xl md:text-4xl font-bold font-heading">Please select a seating time.</h1>
      <div class="py-2 text-base md:text-xs italic text-fg-disclaimer">{{ seatingTimeText }}</div>

      <div class="flex flex-col">
        <SlotButton
          v-for="(seatingTime, index) in seatingTimes"
          :key="seatingTime.id"
          class="w-52 transition-opacity my-2 mx-2 p-2 rounded font-semibold text-sm font-button bg-button-guest-primary text-button-label enabled:hover:opacity-80 disabled:cursor-not-allowed disabled:bg-disabled"
          :class="`seating-time-${index + 1}`"
          :disabled="seatingTime.sold"
          :id="`seating-time-${seatingTime.id}`"
          @click="checkoutStore.setSeatingTime(seatingTime, ui.error)"
          guest
        >
          {{ seatingTime.time }}
          <span v-if="seatingTime.sold"> ( SOLD OUT ) </span>
        </SlotButton>
      </div>

      <div class="mt-2 px-4" :class="isCheckoutDesktopFlow ? 'mt-80' : 'mt-auto pb-10'">
        <ButtonDefault
          label="Back"
          class="font-semibold uppercase text-sm px-4 py-1.5 border border-solid border-button-guest-primary rounded font-button text-button-guest-primary"
          guest
          @click="onBack"
        />
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import * as Sentry from '@sentry/vue'
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import ButtonDefault from '@/common/components/atoms/ButtonDefault.vue'
import SlotButton from '@/common/components/atoms/SlotButton.vue'
import { StorePerformance, StorePerformanceSeatingTime } from '@/models'
import StatusBar from '@/modules/guestUi/components/organisms/StatusBar.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { blankError } from '@/utils/errors'
import { sortBy } from '@/utils/utils'

interface DisplaySeatingTime extends StorePerformanceSeatingTime {
  sold: boolean
}

export default defineComponent({
  components: {
    ButtonDefault,
    SlotButton,
    StatusBar,
  },
  data() {
    return {
      ui: {
        error: blankError(),
      },
    }
  },

  async created() {
    if (!this.checkoutStore.performance) {
      Sentry.captureException(new Error(`Arrived at SetSeatingTime with no performance set`))
      await this.$router.replace('/')
      return
    }
    await this.checkoutStore.refreshPerformance()
  },

  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),

    currentPerformance(): StorePerformance {
      if (!this.checkoutStore.performance) {
        this.$router.replace('/')
        throw new Error("Can't be in SetSeatingTime without a performance")
      }
      return this.checkoutStore.performance
    },

    seatingTimes(): DisplaySeatingTime[] {
      return sortBy(
        (this.currentPerformance.seating_times || []).map(seating_time => ({
          ...seating_time,
          sold:
            seating_time.maximum_guests - (seating_time.reserved_guests || 0) <
            this.checkoutStore.number_of_guests,
        })),
        'time24',
      )
    },

    seatingTimeText(): string {
      return (
        this.settingStore.seating_time_text ||
        'Seating times are staggered before the show. This helps us provide the best service from our kitchen and bar.'
      )
    },

    isCheckoutDesktopFlow(): boolean {
      return this.checkoutStore.isDesktopFlow
    },
  },

  mounted() {
    this.checkoutStore.clearSeatingTime()
  },

  methods: {
    onBack() {
      history.go(-1)
    },
  },
})
</script>
