import { defineStore } from 'pinia'

import * as api from '@/api/api'
import { ShowImageCrop, StoreShow, crop2position, parseShow } from '@/models'
import { byId, deleteById, gtmEvt, sortByName, upsertById } from '@/utils/utils'

import { ShowRead, ShowWrite } from '@generated/types'

export interface ShowState {
  shows: StoreShow[]
}

export const useShowStore = defineStore({
  id: 'show',
  state: (): ShowState => {
    return {
      shows: [],
    }
  },
  actions: {
    sortShows() {
      sortByName(this.shows)
    },

    showById(id: number): StoreShow {
      let show = this.shows.find(byId(id))
      if (!show) {
        throw new Error(`Show ${id} is not found`)
      }
      return show
    },

    async fetchShows(): Promise<void> {
      const response = await api.getShows()
      this.shows = response.data.map(parseShow)
      this.sortShows()
    },

    async fetchShow(id: number): Promise<StoreShow> {
      const response = await api.getShow(id)
      return this.storeShow(response.data)
    },

    storeShow(showRead: ShowRead): StoreShow {
      const show = parseShow(showRead)
      upsertById(show, this.shows)
      this.sortShows()
      return show
    },

    async upsertShow(showWrite: ShowWrite): Promise<StoreShow> {
      gtmEvt('upsertShow')
      const response = !showWrite.id
        ? await api.addShow(showWrite)
        : await api.updateShow(showWrite)
      return this.storeShow(response.data)
    },

    async updateShowImage({ id, image }): Promise<StoreShow> {
      gtmEvt('updateShowImage')
      let formData = new FormData()
      formData.append('file', image)
      const response = await api.updateShowImage(id, formData)
      return this.storeShow(response.data)
    },

    async updateShowImageCrop({
      id,
      crop,
    }: {
      id: number
      crop: ShowImageCrop
    }): Promise<StoreShow> {
      gtmEvt('updateShowImagePosition')
      const response = await api.updateShowImagePosition(id, { position: crop2position(crop) })
      return this.storeShow(response.data)
    },

    async deleteShow(id: number): Promise<void> {
      gtmEvt('deleteShow')
      await api.deleteShow(id)
      deleteById(id, this.shows)
    },

    async duplicateShow(id: number): Promise<StoreShow> {
      gtmEvt('duplicateShow')
      const response = await api.duplicateShow(id)
      return this.storeShow(response.data)
    },
  },
})
