<template>
  <div
    id="skeleton-placeholder"
    class="animate-pulse relative overflow-hidden"
    :class="[bgClass, loaderClass]"
  >
    <div class="absolute top-0 right-0 bottom-0 left-0"></div>
    <slot />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'

type LoaderTypesKeys = keyof typeof LOADER_TYPES
type LoaderTypesValues = (typeof LOADER_TYPES)[LoaderTypesKeys]

const LOADER_TYPES = { rectangle: 'rectangle', circle: 'circle' }
const LOADER_CSS_CLASSES = {
  [LOADER_TYPES.rectangle]: 'rounded',
  [LOADER_TYPES.circle]: 'rounded-full',
}

export default defineComponent({
  name: 'SkeletonPlaceholder',
  props: {
    shape: {
      type: String as PropType<LoaderTypesValues>,
      default: LOADER_TYPES.rectangle,
      validator(value: LoaderTypesValues) {
        return Object.values(LOADER_TYPES).includes(value)
      },
    },
    bgClass: {
      type: String,
      default: 'bg-surface-secondary',
    },
  },
  computed: {
    loaderClass(): (typeof LOADER_CSS_CLASSES)[LoaderTypesValues] {
      return LOADER_CSS_CLASSES[this.shape]
    },
  },
})
</script>
