<template>
  <div
    v-if="checkoutStore.hasPromoCodeApplied"
    id="promo-code-info"
    class="flex justify-between text-sm"
  >
    <div>{{ promoCodeDisplay }}</div>
    <div class="text-right">-{{ currency(pricing.discounted) }}</div>
  </div>
  <PromoBox v-else-if="hasPromos" id="checkout-promo-box" />
</template>

<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import PromoBox from '@/modules/guestUi/components/molecules/PromoBox.vue'
import { OrderPricing } from '@/orders'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

import { FeatureFlag } from '@generated/types'

export default defineComponent({
  name: 'CheckoutPromoCode',
  components: {
    PromoBox,
  },
  computed: {
    ...mapStores(useCheckoutStore, useSettingStore),
    ...mapState(useSettingStore, ['currency']),
    pricing(): OrderPricing {
      return this.checkoutStore.pricing
    },
    promoCodeDisplay(): string {
      const code = this.checkoutStore.promo_code
      if (!code) return ''
      return `${code.name} (${code.coupon.name})`
    },
    hasPromos(): boolean {
      return this.settingStore.flagEnabled(FeatureFlag.PROMO_CODES)
    },
  },
})
</script>
