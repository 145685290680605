<template>
  <section class="container mx-auto mb-16 mt-8">
    <h1 class="font-semibold text-lg">Terms and Conditions of Use</h1>
    <h2>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE.</h2>
    <p>
      These are the Terms and Conditions of Use (“Terms”) governing the use of this “Site” ({{
        siteName
      }}) and the agreement that operates between You, the user, and Turntable Tickets, INC
      (“Turntable”). These Terms describe the rights and obligations of all users regarding the use
      of the Site. Turntable may immediately terminate these Terms or any Site with respect to you,
      or generally cease offering or deny access to the Site or any portion thereof, at any time for
      any reason, except where prohibited. Your access to and use of the Site is conditioned on your
      acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and
      others who access or use the Site.
    </p>
    <ol>
      <li>
        <strong>Who May Use The Site</strong><br />
        <p>
          Except as otherwise set forth herein, You may only use the Site if You can form a legally
          binding contract. You must use the Site in compliance with these Terms and all applicable
          laws. If You submit information to Us through this Site, You must provide Us with accurate
          and complete information, and You agree to update Your information to keep it accurate and
          complete. Submission of information through this website by anyone under the age of 13 is
          strictly prohibited.
        </p>
      </li>
      <li>
        <strong>Account Registration</strong><br />
        <p>
          To use certain areas of the Site, You may need to create an account (“Account”). You agree
          to provide Us with accurate, complete, and updated information for Your Account. You can
          access, edit and update Your Account by logging into Your Account on the Site. You are
          responsible for maintaining the confidentiality of Your Account and password and for
          restricting access to Your account, and You agree to accept responsibility for all
          activities that occur under Your account or password. You will have no ownership in Your
          account or Your username. We may refuse registration, cancel an account, or deny access to
          the Site for any reason.
        </p>
      </li>
      <li>
        <strong>Code of Conduct</strong><br />
        <p>
          You agree that You will comply with all applicable laws, rules, and regulations, and You
          will not:
        </p>
        <ul>
          <li>Restrict or inhibit any other person from using the Site;</li>
          <li>Use the Site for any unlawful purpose;</li>
          <li>
            Express or imply that any statements You make are endorsed by us, without Our prior
            written consent;
          </li>
          <li>
            Impersonate any person or entity, whether actual or fictitious, including any employee
            or representative of Turntable;
          </li>
          <li>
            Submit (a) any content or information that is unlawful, fraudulent, libelous,
            defamatory, or otherwise objectionable, or infringes Our or any third party’s
            intellectual property or other rights; (b) any non-public information about companies
            without authorization; or (c) any advertisements, solicitations, chain letters, pyramid
            schemes, surveys, contests, investment opportunities or other unsolicited commercial
            communication;
          </li>
          <li>
            Submit, or provide links to, any postings containing material that could be considered
            harmful, obscene, pornographic, sexually explicit, indecent, lewd, violent, abusive,
            profane, insulting, threatening, harassing, hateful or otherwise objectionable, includes
            the image or likeness of individuals under 18 years of age, encourages or otherwise
            depicts or glamorizes drug use (including alcohol and cigarettes), characterizes
            violence as acceptable, glamorous or desirable, or contains any personal contact
            information or other personal information identifying any third party;
          </li>
          <li>
            Submit, or provide links to, any postings containing material that harasses, victimizes,
            degrades, or intimidates an individual or group of individuals on the basis of religion,
            race, ethnicity, sexual orientation, gender, age, or disability;
          </li>
          <li>
            Order a number of tickets for an event that exceeds the stated limit for that event; or
          </li>
          <li>
            Use any password or code to participate in a presale or other offer on the Site if You
            did not receive the password or code from Us or if You violate the terms of the presale
            or offer.
          </li>
        </ul>
      </li>
      <li>
        <strong>Making Purchases</strong><br />
        <p>
          Please review Our Purchase Policy, which will govern Your purchase of any tickets or other
          products through the Site, including any refunds or exchanges. We may impose conditions on
          Your use of any coupon, promotional code or gift card. You will pay all charges incurred
          by You or any users of Your account and credit card (or other applicable payment
          mechanism) at the price(s) in effect when such charges are incurred, including any
          applicable taxes. You may only use credit or debit cards, gift cards or vouchers that
          belong to You or to people who expressly authorize You to use such payment methods. You
          may not attempt to conceal Your identity by using multiple Internet Protocol addresses or
          email addresses to conduct transactions on the Site. You will not hold Us liable if You do
          not comply with laws related to Your transactions. We may provide law enforcement with
          information You provide to Us related to Your transactions to assist in any investigation
          or prosecution of you. If We are unable to verify or authenticate any information or
          tickets You provide during any registration, ordering, purchase, ticket posting, sale,
          authentication, delivery, payment or remittance process, or any other process, or if We
          are no longer able to verify or authorize Your credit card or bank account information,
          Your tickets may be cancelled, We may refuse to honor all pending and future ticket
          purchases made with those credit card or bank accounts and/or via any online accounts
          associated with those credit card or bank accounts. We may also prohibit You from using
          the Site.
        </p>
      </li>
      <li>
        <strong>Claims of Copyright Infringement on the Site</strong><br />
        <p>
          Under the Digital Millennium Copyright Act of 1998 (the “DMCA”) if You believe in good
          faith that any content on the Site infringes Your copyright, You may send Us a notice
          requesting that the content be removed. The notice must include: (a) Your (or Your
          agent’s) physical or electronic signature; (b) identification of the copyrighted work on
          Our Site that is claimed to have been infringed (or a representative list if multiple
          copyrighted works are included in one notification); (c) identification of the content
          that is claimed to be infringing or the subject of infringing activity, including
          information reasonably sufficient to allow Us to locate the content on the Site; (d) Your
          name, address, telephone number and email address (if available); (e) a statement that You
          have a good faith belief that use of the content in the manner complained of is not
          authorized by You or Your agent or the law; and (f) a statement that the information in
          the notification is accurate and, under penalty of perjury, that You or Your agent is
          authorized to act on behalf of the copyright owner.
        </p>
      </li>
      <li>
        <strong>Links</strong><br />
        <p>
          The Site contains links to other websites that may not be owned or operated by us. The
          fact that We may link to those websites does not indicate any approval or endorsement of
          those websites. We have no control over those websites. We are not responsible for the
          content of those websites, or the privacy practices of those websites. We strongly
          encourage You to become familiar with the terms of use and practices of any linked
          website. Your use of other websites is at Your own risk and is subject to the terms of
          those websites. It is up to You to take precautions to ensure that whatever links You
          select or software You download (whether from the Site or other sites) is free of viruses,
          worms, Trojan horses, defects, date bombs, time bombs and other items of a destructive
          nature.
        </p>
      </li>
      <li>
        <strong>Parental Controls</strong><br />
        <p>
          We cannot prohibit minors from visiting Our Site and must rely on parents and guardians to
          decide what materials are appropriate for children to view and purchase. There are
          parental control protections (such as computer hardware, software, or filtering services)
          available that may assist You in limiting access to material that is harmful to minors.
          You can find information about parental controls at http://onguardonline.gov. We do not
          endorse the products or services listed at this website.
        </p>
      </li>
      <li>
        <strong>Access from Outside the United States</strong><br />
        <p>
          The Site is directed solely to people residing or located in the United States. We do not
          represent that Content available on or through the Site is appropriate or available in
          other locations. We may limit the availability of the Site, or any service or product
          described on the Site to any person or geographic area at any time. If You choose to
          access the Site from outside the United States, You do so at Your own risk.
        </p>
      </li>
      <li>
        <strong>Rules for Sweepstakes, Contests and Games</strong><br />
        <p>
          In addition to these Terms, sweepstakes, contests, games, or other promotions
          (collectively, “Promotions”) made available through the Site may have specific rules that
          are different from these Terms. By participating in a Promotion, You will become subject
          to those rules. We urge You to review the rules before You participate in a Promotion.
          Promotion rules will control over any conflict with these Terms.
        </p>
      </li>
      <li>
        <strong>Violation of these Terms</strong><br />
        <p>
          We may investigate any violation of these Terms, including unauthorized use of the Site.
          We may provide law enforcement with information You provide to Us related to Your
          transactions to assist in any investigation or prosecution of you. We may take legal
          action that We feel is appropriate. You agree that monetary damages may not provide Us a
          sufficient remedy and that We may pursue injunctive or other relief for Your violation of
          these Terms. If We determine that You have violated these Terms or the law, or for any
          other reason or for no reason, We may cancel Your account, delete all Your User Content
          and prevent You from accessing the Site at any time without notice to you. If that
          happens, You may no longer use the Site or any Content. You will still be bound by Your
          obligations under these Terms. You agree that We will not be liable to You or any third
          party for termination of Your access to the Site or to Your account or any related
          information, and We will not be required to make the Site or Your account or any related
          information available to you. We may also cancel any ticket or merchandise order, and
          tickets or merchandise acquired through Your order. We may refuse to honor pending and
          future purchases made from all accounts We believe may be associated with You or cancel a
          ticket or ticket order associated with any person We believe to be acting with you, or
          cancel Your ticket postings, or exercise any other remedy available to us.
        </p>
      </li>
      <li>
        <strong>Disclaimer of Warranties</strong><br />
        <p>
          We provide the Site and the content to You “as is” and “as available.” We try to keep the
          Site up, bug-free and safe, but You use it at Your own risk. To the fullest extent
          permissible by law, and to the extent that applicable law permits the disclaimer of
          express or implied warranties, We disclaim all warranties, express or implied, including
          any warranty of title, non-infringement, accuracy, merchantability, fitness for a
          particular purpose, or warranties that may arise from course of dealing or course of
          performance or usage of trade. We do not guarantee that the Site will always be safe,
          secure or error-free or that the Site will always function without disruptions, delays or
          imperfections. We are not responsible for the actions or information of third parties, and
          You release Us from any claims and damages, known and unknown, arising out of or in any
          way connected with any claim You have against any such third parties.
        </p>
      </li>
      <li>
        <strong>Limitation of Liability</strong><br />
        <p>
          In addition to any excuse provided by applicable law, Turntable shall be excused from
          liability for non-delivery or delay in delivery of products and services available through
          the Site arising from any event beyond Our reasonable control, whether or not foreseeable
          by either party, including but not limited to, labor disturbance, war, fire, accident,
          adverse weather, inability to secure transportation, governmental act or regulation, and
          other causes or events beyond Our reasonable control, whether or not similar to those
          which are enumerated above.
        </p>
        <p>
          TURNTABLE SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR
          CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY
          DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE Site,
          REGARDLESS OF THE NEGLIGENCE (EITHER ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF
          TURNTABLE, EVEN IF TURNTABLE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          TURNTABLE SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i)
          YOUR USE OF OR RELIANCE ON THE SITE OR YOUR INABILITY TO ACCESS OR USE THE SITE; OR (ii)
          ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD-PARTY PROVIDER, EVEN IF
          TURNTABLE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TURNTABLE SHALL NOT BE
          LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND TURNTABLE’S
          REASONABLE CONTROL.
        </p>
        <p>
          YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE OR LINKED WEBSITES IS TO STOP USING THE
          SITE OR THOSE SERVICES OR PRODUCTS.
        </p>
      </li>
      <li>
        <strong>Indemnification</strong><br />
        <p>
          You agree to indemnify, defend and hold harmless Turntable and its officers, directors,
          employees, contractors, agents, licensors, service providers, subcontractors, and
          suppliers from and against any and all losses, liabilities, expenses, damages and costs,
          including reasonable attorney’s fees and court costs, arising from or in connection with
          Your use of the Site or Our products or services or any violation or alleged violation by
          You of these Terms or applicable law. We reserve the right, at Our own expense, to assume
          exclusive defense and control of any matter otherwise subject to indemnification by You
          and, in such case, You agree to cooperate with Us in the defense of such matter. You may
          not settle any claim covered by this Section or admit any liability on the part of
          Turntable without Turntable’s prior written approval.
        </p>
      </li>
      <li>
        <strong>Mandatory Arbitration and Class Action Waiver</strong><br />
        <p>
          Any dispute, claim, or controversy relating in any way to these Terms (including
          interpretation or application of this section and questions of arbitrability), to Your use
          of the Site, or to any products or services sold or distributed by Turntable or through
          the Site will be resolved by binding arbitration, rather than in court, except each party
          retains the right to bring an individual action in small claims court or to seek
          injunctive or other equitable relief in a court of competent jurisdiction to prevent the
          actual or threatened infringement, misappropriation, or violation of a party’s copyrights,
          trademarks, trade secrets, patents, or other intellectual property rights. There is no
          judge or jury in arbitration, and court review of an arbitration award is limited.
          However, an arbitrator can award all remedies (except for class, consolidated, or
          representative action remedies) to which a party is entitled under applicable law, and
          which would otherwise be available in a court of law (including injunctive and declaratory
          relief or statutory damages), and must follow these Terms as a court would.
        </p>
        <p>
          It is agreed any dispute resolution proceedings will be conducted on an individual basis
          only, and not in a class, consolidated, or representative action. YOU ACKNOWLEDGE AND
          AGREE THAT YOU AND TURNTABLE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
          PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS.
        </p>
        <p>
          <u>Action or Representative Proceeding</u>. Unless You and Turntable otherwise agree, the
          arbitration will be conducted in the county where You reside. If Your claim does not
          exceed $10,000, then the arbitration will be conducted solely on the basis of documents
          You and Turntable submit to the Arbitrator, unless You request a hearing, or the
          Arbitrator determines a hearing is necessary. If Your claim exceeds $10,000, Your right to
          a hearing will be determined by the American Arbitration Association (“AAA”) Rules.
          Subject to the AAA Rules, the Arbitrator will have the discretion to direct a reasonable
          exchange of information by the parties, consistent with the expedited nature of the
          arbitration.
        </p>
        <p>
          <u>Arbitration Process</u>. As specified in the AAA’s rules, to begin an arbitration
          proceeding, You must send a written Demand for Arbitration that describes Your claim and
          the relief You seek to Our registered agent at {{ ourAgent }}. Payment of all filing,
          administration, and arbitrator fees will be governed by the AAA’s rules.
        </p>
        <p>
          Our failure to exercise or enforce any right or provision in these Terms will not
          constitute a waiver of that right or provision or any other rights or provisions included
          within the Terms.
        </p>
      </li>
    </ol>
  </section>
</template>
<script lang="ts">
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import { useSettingStore } from '@/stores/setting'

export default defineComponent({
  name: 'TermsConditions',
  computed: {
    ...mapStores(useSettingStore),
    siteName(): string {
      return this.settingStore.domain
    },

    ourAgent(): string {
      return 'Rosenblatt Law Firm, 16731 Huebner Rd, San Antonio, TX 78248'
    },
  },
})
</script>
<style scoped>
p {
  font-size: 16px;
  padding: 16px 0;
}
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
</style>
