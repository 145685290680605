<template>
  <div>
    <table class="w-full rounded">
      <thead class="text-xs">
        <tr>
          <th class="text-left h-7">
            <span class="text-xs text-fg-disclaimer">Description</span>
          </th>
          <th class="text-right h-7 px-4">
            <span class="text-xs text-fg-disclaimer">
              {{ addOns && addOns.length > 0 ? 'Qty' : 'Tickets' }}
            </span>
          </th>
          <th class="text-right h-7 px-4">
            <span class="text-xs text-fg-disclaimer">Each</span>
          </th>
          <th class="text-right h-7">
            <span class="text-xs text-fg-disclaimer">Total</span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(table, index) in tables"
          :key="index"
          :id="`table-line-${table.id}`"
          class="text-sm text-fg-g-primary"
        >
          <td :id="`table-line-${table.id}-description`" class="h-7">{{ table.description }}</td>
          <td :id="`table-line-${table.id}-tickets`" class="text-right h-7 px-4">
            {{ table.guests }}
          </td>
          <td :id="`table-line-${table.id}-each`" class="text-right h-7 px-4">
            {{ currency(table.price_per_person) }}
          </td>
          <td :id="`table-line-${table.id}-total`" class="text-right h-7">
            {{ currency(table.guests * table.price_per_person) }}
          </td>
        </tr>
        <template v-if="!!addOns">
          <tr
            v-for="addOn in addOnsInTable"
            :key="addOn.id"
            :id="`addOn-line-${addOn.id}`"
            class="text-sm text-fg-g-primary"
          >
            <td :id="`addOn-line-${addOn.id}-name`" class="h-7">{{ addOn.name }}</td>
            <td :id="`addOn-line-${addOn.id}-quantity`" class="text-right h-7 px-4">
              {{ addOn.quantity }}
            </td>
            <td :id="`addOn-line-${addOn.id}-price`" class="text-right h-7 px-4">
              {{ currency(addOn.price) }}
            </td>
            <td :id="`addOn-line-${addOn.id}-price-total`" class="text-right h-7">
              {{ currency(addOn.quantity * addOn.price) }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="tax-information flex justify-between text-sm mt-1.5 group">
      <div class="relative">
        <div class="peer flex items-baseline space-x-2">
          <span>Taxes &amp; Fees</span>
          <span
            class="text-sm bg-gray-300 text-black flex items-center justify-center h-4 w-4 rounded-full cursor-pointer"
            >?</span
          >
        </div>
        <div
          class="absolute invisible opacity-0 top-0 right-0 transition-all peer-hover:-right-2 peer-hover:visible peer-hover:opacity-100 translate-x-full flex flex-col space-y-1 z-20 p-2 border border-linear-g-primary rounded bg-body-primary shadow text-xs w-48"
        >
          <div class="flex justify-between items-baseline">
            Taxes <span>{{ currency(pricing.tax) }}</span>
          </div>
          <div class="flex justify-between items-baseline">
            Processing Fees <span>{{ currency(pricing.serviceFee) }}</span>
          </div>
        </div>
      </div>

      <div class="text-right">{{ taxesAndFees }}</div>
    </div>
    <div
      v-if="checkoutStore.reservation && isDiscounted"
      class="flex justify-between text-sm mt-1.5"
      id="promo-discount"
    >
      <div class="peer flex items-baseline space-x-2">Promotional Discount</div>
      <div class="text-right">-{{ currency(pricing.discounted) }}</div>
    </div>
    <hr class="bg-base-secondary w-full my-2" />
    <div class="flex items-baseline justify-between">
      <span class="">Total</span>
      <span id="total-price" class="font-semibold">{{ currency(pricing.total) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import { EnrichedAddOn, EnrichedTable, OrderPricing } from '@/orders'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { sortBy } from '@/utils/utils'

import { ReservationAddOnWrite } from '@generated/types'

export default defineComponent({
  name: 'CheckoutDetailTable',
  computed: {
    ...mapStores(useCheckoutStore, useSettingStore),
    ...mapState(useSettingStore, ['currency']),
    addOns(): ReservationAddOnWrite[] | null {
      return this.checkoutStore.add_ons
    },
    addOnsInTable(): EnrichedAddOn[] {
      return sortBy(this.checkoutStore.addOnsModels || [], 'name')
    },
    pricing(): OrderPricing {
      return this.checkoutStore.pricing
    },
    taxesAndFees(): string {
      return this.currency(Number(this.pricing.tax) + Number(this.pricing.serviceFee))
    },
    isDiscounted(): boolean {
      return (this.pricing.discounted || 0) > 0
    },
    tables(): EnrichedTable[] {
      return this.checkoutStore.tableModels
    },
  },
})
</script>
