import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M11.121 1.373 3.625 11.839.894 6.376l1.86-.93 1.189 2.376L9.43.162l1.691 1.21Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }