<template>
  <div
    v-if="isGuest"
    class="xs:flex flex-col p-2 font-sans font-medium text-base md:px-2 md:py-4 md:text-lg"
    :class="isTableSelection ? 'mb-0' : 'mb-16'"
  >
    <div id="status-bar-show-name" class="mx-2">
      {{ showName }}
    </div>

    <template v-if="performanceDate">
      <div id="status-bar-performance-date-time" class="mx-2 text-xs">
        {{ performanceDate.dateStr }}, {{ performanceDate.timeStr }} Show Time<span
          v-if="guestCount"
          >,
        </span>
        <span v-if="guestCount">{{ guestCount }} Guest<span v-if="guestCount > 1">s</span></span>
      </div>
    </template>
  </div>
  <div v-else-if="isOwner" class="flex justify-center">
    <template v-if="!isVenueStripeEnabled && isVenueRoute">
      <a
        href="/stripe/refresh/"
        target="_blank"
        class="cursor-pointer text-center rounded-md bg-surface-primary px-5 py-1 font-semibold text-fg-error -mt-4 inline-block shadow-podHover mx-auto"
      >
        Your Stripe setup is not complete. Please click here to continue.
      </a>
    </template>
  </div>
</template>

<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { useUserStore } from '@/stores/user'

export default defineComponent({
  props: {
    isTableSelection: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentPath: '',
    }
  },

  mounted() {
    this.currentPath = this.$router.currentRoute.value.fullPath
  },

  watch: {
    $route(to) {
      this.currentPath = to.fullPath
    },
  },

  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),
    ...mapState(useCheckoutStore, ['performance']),
    ...mapState(useUserStore, ['isOwner']),

    isGuest() {
      return this.performance && this.performance.show && !this.isOwner && !this.IsOrderDetailsPage
    },

    isVenueStripeEnabled() {
      return this.settingStore.stripe_charges_enabled
    },

    isVenueRoute() {
      return !this.$route.meta?.guest
    },

    IsOrderDetailsPage() {
      return this.currentPath.includes('/order-details')
    },

    performanceDate() {
      if (this.performance) {
        return {
          dateStr: this.settingStore.longDate(this.performance.datetime),
          timeStr: this.settingStore.timeStr(this.performance.datetime),
        }
      }

      return null
    },

    guestCount() {
      return this.checkoutStore.number_of_guests
    },

    showName() {
      return this.performance?.show.name
    },
  },
})
</script>
