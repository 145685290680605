<template>
  <button
    v-if="!guest"
    class="font-heading font-semibold uppercase text-sm px-4 py-1.5"
    :class="[
      disabled
        ? 'disabled bg-button-secondary text-fg-inverse cursor-not-allowed'
        : {
            'bg-button-primary text-fg-oncolor guest-ui:bg-button-guest-primary': !alt && !group,
            'bg-button-secondary text-fg-onsecondary': alt && !disabled,
            '!bg-button-warning text-fg-oncolor': altWarning,
            rounded: !group,
            'bg-surface-primary': group,
          },
      classes,
    ]"
    @click="onClick"
  >
    <slot></slot>
  </button>
  <button
    v-else
    :disabled="disabled"
    :class="[
      {
        'disabled bg-button-secondary text-white cursor-not-allowed': disabled,
      },
    ]"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SlotButton',
  props: {
    classes: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    alt: { type: Boolean, required: false },
    altWarning: { type: Boolean, required: false },
    group: { type: Boolean, required: false },
    guest: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      wait: false,
    }
  },
  emits: ['click', 'click:disabled'],
  methods: {
    onClick() {
      if (!this.wait) {
        if (this.disabled) {
          this.$emit('click:disabled')
        } else {
          this.$emit('click')
        }
        this.wait = true
        setTimeout(() => (this.wait = false), 500)
      } else {
        console.warn('discarding debounced click')
      }
    },
  },
})
</script>
