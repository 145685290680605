<template>
  <div
    class="calendar-show-date text-calendar-primary flex justify-center items-center flex-1 text-sm font-medium py-1 cursor-pointer md:h-40 md:border-l md:border-b md:border-linear-strong md:first:border-l-0 md:pl-1 md:overflow-hidden md:items-start"
    :class="{
      'md:flex-col': hasPerformances,
      'md:justify-start': !hasPerformances,
      'active relative pt-[-1px] bg-calendar-bg-active border-b-calendar-bg-active text-calendar-fg-active xs:rounded-t border-solid border-linear-strong top-px border-t border-r border-l md:border-r-0 md:border-l md:border-t-0 md:rounded-t-none md:top-0 venue-ui:!bg-surface-secondary venue-ui:!border-linear-strong':
        isSelected && inMonth,
      'flex-col w-1/6': showPerformanceInMobile,
      'flex-row': !showPerformanceInMobile,
    }"
  >
    <span
      class="calendar-day w-6 h-6 flex items-center justify-center rounded"
      :class="[
        {
          'opacity-0': !inMonth,
          'border border-primary': isToday,
          'bg-primary text-fg-oncolor venue-ui:bg-fg-link-primary': isSelected,
          'text-primary': hasPerformances && !isSelected,
          'text-fg-tertiary': !hasPerformances && !isSelected,
        },
      ]"
    >
      {{ date.day }}
    </span>
    <div
      v-if="hasPerformances && inMonth"
      class="day-performance-list pb-2 mt-auto flex-col overflow-hidden w-full"
      :class="showPerformanceInMobile ? 'flex' : 'hidden md:flex'"
    >
      <p
        v-for="performance in date.performances"
        :key="performance.id"
        @click.stop="emit('click:performance', performance)"
        class="max-w-full text-calendar-perf-link truncate inline-block venue-ui:text-fg-link-primary py-1 px-2 hover:venue-ui:bg-surface-secondary hover:venue-ui:text-fg-link-primary hover:venue-ui:rounded"
      >
        {{ settingStore.timeStr(performance.datetime) }} - {{ performance.show.name }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { StorePerformance } from '@/models'
import { useSettingStore } from '@/stores/setting'

export interface CalendarDayModel {
  date: string
  day: string
  month: string
  year: string
  performances: StorePerformance[]
}

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (event: 'click:performance', performance: StorePerformance): void
}>()

const props = defineProps<{
  date: CalendarDayModel
  isSelected: boolean
  isToday: boolean
  inMonth: boolean
  showPerformanceInMobile: boolean
}>()
const settingStore = useSettingStore()
const hasPerformances = computed(() => props.date.performances.length > 0)
</script>
