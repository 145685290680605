/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:   radio.js
 *
 *   Desc:   Radio group widget that implements ARIA Authoring Practices
 */

'use strict'

export class RovingTabindex {
  groupNode: Element
  radioButtons: Element[]
  firstRadioButton: Element | null
  lastRadioButton: Element | null

  constructor(groupNode: Element) {
    this.groupNode = groupNode

    this.radioButtons = []

    this.firstRadioButton = null
    this.lastRadioButton = null

    const rbs = this.groupNode.querySelectorAll('[role=radio]')

    for (let i = 0; i < rbs.length; i++) {
      const rb = rbs[i] as Element

      rb.tabIndex = -1
      rb.setAttribute('aria-checked', 'false')

      rb.addEventListener('keydown', this.handleKeydown.bind(this))
      rb.addEventListener('click', this.handleClick.bind(this))
      rb.addEventListener('focus', this.handleFocus.bind(this))
      rb.addEventListener('blur', this.handleBlur.bind(this))

      this.radioButtons.push(rb)

      if (!this.firstRadioButton) {
        this.firstRadioButton = rb
      }
      this.lastRadioButton = rb
    }
    if (this.firstRadioButton) {
      this.firstRadioButton.tabIndex = 0
    }
  }

  setChecked(currentItem: Element) {
    for (let i = 0; i < this.radioButtons.length; i++) {
      const rb = this.radioButtons[i]
      rb.setAttribute('aria-checked', 'false')
      rb.tabIndex = -1
    }
    currentItem.setAttribute('aria-checked', 'true')
    currentItem.tabIndex = 0
    currentItem.focus()
  }

  setCheckedToPreviousItem(currentItem: Element) {
    let index: number

    if (currentItem === this.firstRadioButton) {
      if (this.lastRadioButton) {
        this.setChecked(this.lastRadioButton)
      }
    } else {
      index = this.radioButtons.indexOf(currentItem)
      this.setChecked(this.radioButtons[index - 1])
    }
  }

  setCheckedToNextItem(currentItem: Element) {
    let index: number

    if (currentItem === this.lastRadioButton) {
      if (this.firstRadioButton) {
        this.setChecked(this.firstRadioButton)
      }
    } else {
      index = this.radioButtons.indexOf(currentItem)
      this.setChecked(this.radioButtons[index + 1])
    }
  }

  /* EVENT HANDLERS */

  handleKeydown(event: KeyboardEvent) {
    const tgt = event.currentTarget as Element
    let flag = false

    switch (event.key) {
      case ' ':
        this.setChecked(tgt)
        flag = true
        break

      case 'Up':
      case 'ArrowUp':
      case 'Left':
      case 'ArrowLeft':
        this.setCheckedToPreviousItem(tgt)
        flag = true
        break

      case 'Down':
      case 'ArrowDown':
      case 'Right':
      case 'ArrowRight':
        this.setCheckedToNextItem(tgt)
        flag = true
        break

      default:
        break
    }

    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  handleClick(event: MouseEvent) {
    const tgt = event.currentTarget as Element
    this.setChecked(tgt)
  }

  handleFocus(event: FocusEvent) {
    const tgt = event.currentTarget as Element
    tgt.classList.add('focus')
  }

  handleBlur(event: FocusEvent) {
    const tgt = event.currentTarget as Element
    tgt.classList.remove('focus')
  }
}
