<template>
  <section class="container mx-auto mb-16 mt-8">
    <h1 class="font-semibold text-lg mb-4">Legal</h1>

    <ol class="list-disc list-inside">
      <li>
        <a
          class="text-secondary"
          href="https://assets-prod.turntabletickets.com/static/Website-Terms-and-Conditions-of-Use-2024-05-01.pdf"
          target="_blank"
        >
          Website-Terms-and-Conditions-of-Use-2024-05-01
        </a>
      </li>
      <li>
        <a
          class="text-secondary"
          href="https://assets-prod.turntabletickets.com/static/Turntable-Privacy-Policy-2024-05-01.pdf"
          target="_blank"
        >
          Turntable-Privacy-Policy-2024-05-01
        </a>
      </li>
      <li>
        <a
          class="text-secondary"
          href="https://assets-prod.turntabletickets.com/static/Purchase-Policy-2024-04-17.pdf"
          target="_blank"
        >
          Purchase-Policy-2024-04-17
        </a>
      </li>
      <li>
        <a
          class="text-secondary"
          href="https://assets-prod.turntabletickets.com/static/Turntable-Accessibility-Statement-2024-04-17.pdf"
          target="_blank"
        >
          Turntable-Accessibility-Statement-2024-04-17
        </a>
      </li>
    </ol>
  </section>
</template>

<script setup lang="ts"></script>
