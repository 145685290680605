<template>
  <label
    class="font-label text-fg-secondary text-sm guest-ui:text-fg-disclaimer block"
    :for="forInput"
  >
    <slot></slot><span v-if="isOptional" id="optional-label"> (optional)</span>
  </label>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue'

const props = withDefaults(defineProps<{ isOptional?: boolean; forInput?: string }>(), {
  isOptional: false,
  forInput: '',
})
const { isOptional } = toRefs(props)
</script>
