<template>
  <SlotButton
    :classes="classes"
    :disabled="disabled"
    :alt="alt"
    :altWarning="altWarning"
    :group="group"
    :guest="guest"
    @click="$emit('click')"
    @click:disabled="$emit('click:disabled')"
  >
    {{ label }}
  </SlotButton>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import SlotButton from '@/common/components/atoms/SlotButton.vue'

export default defineComponent({
  name: 'ButtonDefault',
  props: {
    label: { type: String, required: true },
    classes: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
    alt: { type: Boolean, required: false },
    altWarning: { type: Boolean, required: false },
    group: { type: Boolean, required: false },
    guest: { type: Boolean, required: false, default: false },
  },
  components: { SlotButton },
  emits: ['click', 'click:disabled'],
})
</script>
