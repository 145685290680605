<template>
  <section :class="{ 'h-screen': isCheckoutDesktopFlow }">
    <div
      v-if="!isCheckoutDesktopFlow"
      class="flex flex-col justify-center bg-primary text-fg-table-label min-h-96px mb-2"
    >
      <template v-if="!!selectedTableIds.length">
        <TableListViewSelected
          uid="guest-selected-table-header"
          :tables="seatedTables"
          :selectable="reservedGuests !== totalGuests"
          :totalGuests="totalGuests"
        />
      </template>
      <template v-else>
        <StatusBar class="justify-center text-white" id="set-tables-status-bar" isTableSelection />
      </template>
    </div>

    <section class="mx-auto h-full">
      <div class="w-full px-4 h-full">
        <div class="flex justify-between items-end mb-10">
          <h1 class="text-base xs:text-2xl md:text-4xl px-4 font-bold font-heading">
            Please select tables.
          </h1>

          <div class="grow text-right">
            <div class="text-xs md:text-base">Guests</div>
            <div class="text-xl md:text-4xl font-bold font-heading">
              <span
                id="guests-reserved"
                tabindex="0"
                :aria-label="`Reserved guests: ${reservedGuests} out of ${totalGuests}`"
              >
                {{ reservedGuests }}/{{ totalGuests }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="w-full grow"
          :class="isCheckoutDesktopFlow ? 'h-[70%] mb-6' : 'h-full mb-8'"
          ref="tables"
        >
          <SetTablesRoomMap />
        </div>

        <div v-if="ui.errorOnReservation.message" id="error-on-reservation" class="mb-2">
          Tables already reserved. Please select other tables.
        </div>
        <div
          class="flex justify-between"
          :class="isCheckoutDesktopFlow ? '' : 'pt-4 pb-8 sticky bottom-0 bg-body-primary'"
        >
          <ButtonDefault
            label="Back"
            class="font-semibold uppercase text-sm px-4 py-1.5 border border-solid border-button-guest-primary rounded font-button text-button-guest-primary"
            guest
            @click="onBack"
          />

          <ButtonDefault
            id="next-button"
            :label="isCreatingReservation ? 'Checking availability...' : 'Next'"
            :disabled="reservedGuests < totalGuests || isCreatingReservation"
            class="px-4 uppercase border border-solid rounded font-semibold bg-button-guest-primary font-button text-button-label border-button-guest-primary disabled:bg-disabled disabled:cursor-not-allowed disabled:border-disabled disabled:text-white"
            @click="onNext"
            guest
          />
        </div>
      </div>
    </section>
  </section>
</template>

<style lang="scss" scoped>
.alert-message {
  margin: 20px 0px;
  padding: 20px 10px;
  background: rgb(200, 0, 0);
  color: white;
  opacity: 0;
  transition: opacity 0.5s;
  text-align: center;
}
.alert-message.active {
  opacity: 1;
}
</style>

<script lang="ts">
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import ButtonDefault from '@/common/components/atoms/ButtonDefault.vue'
import { StorePerformance } from '@/models'
import TableListViewSelected from '@/modules/guestUi/components/molecules/TableListViewSelected.vue'
import SetTablesRoomMap from '@/modules/guestUi/components/organisms/SetTablesRoomMap.vue'
import StatusBar from '@/modules/guestUi/components/organisms/StatusBar.vue'
import { EnrichedTable, tablesForSeatedParty } from '@/orders'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { blankError, displayHandled } from '@/utils/errors'
import { sum } from '@/utils/utils'

import { PerformanceTableId } from '@generated/types'

export default defineComponent({
  components: {
    SetTablesRoomMap,
    StatusBar,
    TableListViewSelected,
    ButtonDefault,
  },

  data() {
    return {
      width: 100,
      ui: {
        error: blankError(),
        errorOnReservation: blankError(),
      },
      isCreatingReservation: false,
    }
  },

  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),

    selectedTableIds(): PerformanceTableId[] {
      return this.checkoutStore.tables
    },

    currentPerformance(): StorePerformance | null {
      return this.checkoutStore.performance
    },

    totalGuests(): number {
      return this.checkoutStore.number_of_guests
    },

    reservedGuests(): number {
      let guests = 0
      guests = sum(this.seatedTables.map(table => table.guests))

      return Math.min(guests, this.totalGuests)
    },

    seatedTables(): EnrichedTable[] {
      return tablesForSeatedParty(this.currentPerformance, this.selectedTableIds, this.totalGuests)
    },

    isCheckoutDesktopFlow(): boolean {
      return this.checkoutStore.isDesktopFlow
    },
  },

  async mounted() {
    const tables = this.$refs.tables as HTMLElement
    tables.querySelector('svg#tlv')?.setAttribute('height', '100%')
  },

  methods: {
    onBack() {
      history.go(-1)
    },

    async onNext() {
      this.ui.errorOnReservation = blankError()
      this.isCreatingReservation = true
      try {
        await this.checkoutStore.setTables(this.checkoutStore.tables, this.ui.error)
      } catch (error) {
        displayHandled(error, this.ui.errorOnReservation)
      } finally {
        this.isCreatingReservation = false
      }
    },
  },
})
</script>
