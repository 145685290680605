<template>
  <section class="">
    <template v-if="!hasPromoCodeApplied">
      <div
        @click="ui.form = true"
        id="apply-promo-code"
        class="cursor-pointer text-sm text-fg-g-primary"
        :class="ui.form ? 'hidden' : 'block'"
      >
        Apply Promo Code
      </div>

      <div class="flex flex-col" :class="ui.form ? 'block' : 'hidden'">
        <div class="text-sm uppercase">Promo Code</div>
        <div class="flex">
          <input
            type="text"
            class="border border-solid bg-body-primary p-2 mr-2"
            v-model="promoCode"
          />
          <ButtonDefault
            @click="onApply"
            class="px-4 border border-solid border-linear-g-primary rounded uppercase font-button text-button-guest-primary"
            label="Apply"
            guest
          />
        </div>
      </div>
      <ErrorDisplay v-bind="ui.error" />
    </template>
  </section>
</template>
<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import ButtonDefault from '@/common/components/atoms/ButtonDefault.vue'
import ErrorDisplay from '@/common/components/atoms/ErrorDisplay.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { blankError, clearError, displayHandled } from '@/utils/errors'

export default defineComponent({
  name: 'PromoBox',
  components: {
    ButtonDefault,
    ErrorDisplay,
  },
  data() {
    return {
      promoCode: null as string | null,
      ui: {
        error: blankError(),
        form: false,
      },
    }
  },
  computed: {
    ...mapStores(useCheckoutStore),
    ...mapState(useCheckoutStore, ['hasPromoCodeApplied']),
  },
  methods: {
    async onApply() {
      clearError(this.ui.error)
      if (!this.promoCode) return
      try {
        const promotion = await this.checkoutStore.validatePromotionCode(this.promoCode)
        await this.checkoutStore.setPromoCode(promotion)
      } catch (err) {
        displayHandled(err, this.ui.error)
      }
    },
  },
})
</script>
