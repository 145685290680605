<template>
  <section class="container mx-auto mb-16 mt-8">
    <h1 class="font-semibold text-lg">Purchase Policy</h1>
    <p>
      Our goal at Turntable Tickets, Inc. (“Turntable” “we” or “us”) is to make the experience of
      purchasing tickets efficient and straightforward. This Purchase Policy has been adopted to
      ensure your understanding of the process to purchase tickets on Turntabletickets.com (the
      “Site”). As used herein, both “you” and the “purchaser” shall describe the person completing
      the transaction for the purchase of tickets on the Site. As mentioned in this Purchase Policy,
      the terms and conditions herein apply only to the purchase of tickets and in no way apply to
      the actions, interactions, or procedures applicable to the Event for which the tickets are
      purchased.
    </p>
    <h3>WHO WE ARE/WHAT WE DO</h3>
    <p>
      Turntable provides a platform to sell tickets and collect money on behalf of artists or venues
      (an “Event Provider”) for a scheduled show, experience, or other gathering (each, an “Event”).
      We are not involved in any aspect of the Event, venue, operations, or management of the Event.
    </p>
    <h3>EVENT INFORMATION</h3>
    <p>
      All details of Events including, but not limited to, performance times, venue, ticket prices,
      and seat availability are entered onto the Turntable website by the Event Provider. The
      accuracy of all such information is at all times the responsibility of the Event Provider.
    </p>
    <h3>PRICING INFORMATION</h3>
    <p>
      We sell tickets on behalf of Event Providers, which means we do not set the ticket prices,
      ticket quantity, or determine seating locations. Event Providers set the price of their
      tickets. Occasionally, additional tickets may be available prior to the Event. However, we do
      not control this inventory or its availability.
    </p>
    <p>
      <strong>Taxes and Fees</strong>. A convenience fee or a processing fee is assessed for each
      ticket purchased. The purpose of any fees collected in conjunction with the actual cost a
      ticket includes costs associated with Turntable’s operation, customer service center
      operation, obtaining tickets on behalf of the purchaser, and other costs associated with the
      fulfillment of the ticket purchase. Each Event Provider is responsible, in its sole
      discretions, for determining the amount of total Ticket Price which is to be allocated to
      Turntable and any taxes. Each Event Provider is solely obligated to determine the portion of
      the Ticket price which is to be allocated towards taxes for its profit earned from the Ticket
      sale.
    </p>
    <h3>ORDER CONFIRMATION AND PROCESSING</h3>
    <p>
      All information on accounts and orders must be valid and is subject to verification. Orders
      are subject to credit card approval, and are processed only after the billing address
      associated with your credit card and other billing information have been verified. Your order
      is confirmed when we send you a confirmation, in the form of a confirmation page or email
      (“Order Confirmation”). If you do not receive an Order Confirmation after submitting payment
      information, or if you experience an error message or service interruption after submitting
      payment information, it is your responsibility to confirm with Turntable whether or not your
      order has been placed.
    </p>
    <p>
      <strong>Delivery Options</strong>. Unless otherwise authorized by the Event Provider, all
      tickets purchased through the Site will be delivered to the email address provided at the time
      of purchase. Delivery options are determined by the Event Provider, in its sole discretion.
    </p>
    <h3>“TICKET LIMITS”</h3>
    <p>
      Event Providers have the option to set a maximum amount that can be purchased by a single
      purchaser (a “Ticket Limit”) to discourage unfair ticket purchase practices. Should the Event
      Provider choose to set a Ticket Limit, the limit specifications will be posted on the Event’s
      page on the Site and will be displayed during the purchase process. Multiple accounts may not
      be used to circumvent or exceed published ticket limits. Any attempted or actual circumvention
      of the Ticket Limit by a purchaser will result in the termination of any further use of any
      Turntable services and the nullification of any tickets purchased.
    </p>
    <h3>CANCELED, POSTPONED, RESCHEDULED, AND MOVED EVENTS</h3>
    <p>
      Occasionally, Events are canceled, postponed, rescheduled to a different date or materially
      different time, or moved to a different venue.
    </p>
    <p>
      <strong>Event Cancelled/Postponed by Event Provider</strong>. For exact instructions on any
      cancelled or postponed Event, please check the Event’s information online or contact the Event
      Provider. Turntable usually only offers refunds and/or exchanges in accordance with the Event
      Provider’s policies and it is their sole discretion whether to offer a refund or an exchange.
      In order to receive a refund or an exchange that may be offered, you must comply with the
      Event Provider’s procedure. While Turntable makes an effort to convey the refund/exchange
      process for an Event Provider, including simply providing the purchaser with a form or contact
      information, Turntable is not responsible for contacting the purchaser when an Event is
      cancelled or postponed. All information, policies, procedures, or requirements associated with
      a cancelled or postponed Event are to be made in the sole discretion of the Event Provider.
    </p>
    <h3>REFUNDS, CREDITS, AND EXCHANGES</h3>
    <p>
      All sales are final and refunds are only allowed in limited circumstances and typically with
      authorization from the Event Provider. Before purchasing tickets, carefully review your Event
      and seat selection. Policies set forth by Event Providers generally prohibit Turntable from
      issuing exchanges or refunds after a ticket has been purchased, or for lost, stolen, damaged,
      or destroyed tickets. Tickets therefore cannot be replaced if they are lost, stolen, or
      damaged.
    </p>
    <h3>LIMITATION OF LIABILITY</h3>
    <p>
      You acknowledge and agree that by purchasing a ticket to the Event and/or attending the Event
      and/or using any corresponding amenity, you may be engaging in activities that may involve
      risk of serious injury, including permanent disability and death, property loss and severe
      social and economic losses. These risks include, without limitation, those caused by or in
      connection with: (a) the actions, inactions or negligence of a third party, and participants,
      volunteers, performers, guests and spectators at the Event; (b) conditions of any premises or
      equipment used; (c) temperature and/or weather; (d) condition of other participants or guests;
      (e) vehicular traffic; (f) offensive language or other vulgar or obscene material or actions;
      and (g) other risks that are not known or foreseeable at this time.
    </p>
    <p>
      TURNTABLE IS NOT LIABLE FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS, WARRANTIES,
      BREACHES, OR NEGLIGENCE OF ANY SUCH THIRD PARTIES OR FOR ANY PERSONAL INJURIES, DEATH,
      PROPERTY DAMAGE, OR OTHER DAMAGES OR EXPENSES RESULTING THEREFROM. TURNTABLE SHALL HAVE NO
      LIABILITY IN THE EVENT OF ANY DELAY, CANCELLATION, OVERBOOKING, STRIKE, FORCE MAJEURE, OR
      OTHER CAUSES BEYOND ITS DIRECT CONTROL, AND IT HAS NO RESPONSIBILITY FOR ANY ADDITIONAL
      EXPENSE, OMISSIONS, DELAYS OR ACTS OF ANY GOVERNMENT OR AUTHORITY.
    </p>
    <p>
      <strong>Alcoholic Beverages</strong>. The purchaser acknowledges and understands alcoholic
      beverages may be available at the Event and that attendance at the Event may expose you to the
      risks associated with the consumption of alcoholic beverages. The purchaser further
      acknowledges and understands all sales of alcoholic beverages of whatever kind, are conducted
      in the sole discretion of the Event Provider and are in no way under control of Turntable.
      Under no circumstances shall Turntable be responsible for actions taken by purchaser, other
      Event attendees, or employees or representatives of Event Provider with respect to the
      consumption or sale of alcoholic beverages.
    </p>
    <h3>License; Ejection and Cancelation; No Redemption Value</h3>
    <p>
      You agree to comply with all of the Event Provider’s applicable rules, policies, terms, and
      conditions (“Event Provider Rules”). The Event Provider reserves the right, without refund of
      any amount paid, to refuse admission to, or eject, any person whose conduct management deems
      disorderly, who uses vulgar or abusive language, or who fails to comply with Event Provider
      Rules.
    </p>
    <h3>INDEMNIFICATION.</h3>
    <p>
      By purchasing a ticket on the Site, you agree to defend, indemnify and hold harmless Turntable
      from and against any and all liabilities, claims, damages, costs and expenses, including
      attorneys’ fees, arising out of or in connection with (a) your transaction with Turntable
      and/or use of the Site; (b) your violation or breach, or alleged violation or breach, of this
      Policy or other applicable policies, including, without limitation, all representations,
      warranties, terms, conditions, covenants, acknowledgments and agreements herein; (c) your
      attendance at or in connection with the Event (including a virtual experience), including,
      without limitation, any removal behavior or other actions or omissions by you; and/or (d) your
      use of a personal recording or mobile application.
    </p>
    <h3>MISCELLANEOUS.</h3>
    <p>
      Please refer to the Terms and Conditions of Use for all information regarding dispute
      resolution, jurisdiction, and mandatory arbitration.
    </p>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PurchasePolicy',
})
</script>
<style scoped>
h3 {
  font-weight: 600;
  margin-bottom: 2px;
}
p {
  font-size: 16px;
  padding: 0 0 8px 0;
}
</style>
