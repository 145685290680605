import {
  PerformanceTableId,
  PerformanceTableRead,
  ReservationId,
  ReservationStatusType,
} from '@generated/types'

export interface PerformanceTableEvent {
  tableId: PerformanceTableId
  tableIndex: number
  activeReservationId: ReservationId | null
}

export type TableUpdateStatus = (typeof TABLE_UPDATE_STATUS)[keyof typeof TABLE_UPDATE_STATUS]
export const TABLE_UPDATE_STATUS = {
  AVAILABLE: 'available',
  UPDATED: 'updated',
  NEW: 'new',
}
export type TableStatus = ReservationStatusType | TableUpdateStatus

export type UpdateIndicatorStatus =
  (typeof UPDATE_INDICATOR_STATUS)[keyof typeof UPDATE_INDICATOR_STATUS]
export const UPDATE_INDICATOR_STATUS = {
  NEW: 'new',
  UPDATED: 'updated',
  NONE: 'none',
}

export type PerformanceTableMixed = {
  [K in keyof PerformanceTableRead]: Array<PerformanceTableRead[K]>
}
