import { RouteRecordRaw } from 'vue-router'

import CalendarView from '@/modules/guestUi/pages/CalendarView.vue'
import GuestLegal from '@/modules/guestUi/pages/GuestLegal.vue'
import PurchasePolicy from '@/modules/guestUi/pages/PurchasePolicy.vue'
import ResidencyHub from '@/modules/guestUi/pages/ResidencyHub.vue'
import SetShow from '@/modules/guestUi/pages/SetShow.vue'
import ShowDetails from '@/modules/guestUi/pages/ShowDetails.vue'
import TermsConditions from '@/modules/guestUi/pages/TermsConditions.vue'

export const ssrRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'setShow',
    component: SetShow,
    meta: {
      guest: true,
      header: true,
      ssr: true,
      pageTitle: 'Show List',
    },
  },
  {
    path: '/shows/:showId/',
    name: 'showDetails',
    component: ShowDetails,
    meta: {
      guest: true,
      header: true,
      ssr: true,
      pageTitle: 'Show Detail',
    },
  },
  {
    path: '/events',
    name: 'calendar',
    component: CalendarView,
    meta: {
      guest: true,
      header: true,
      ssr: true,
      pageTitle: 'Calendar',
      robots: 'noindex',
    },
  },
  {
    path: '/r/:slug',
    name: 'residency-hub',
    meta: {
      guest: true,
      ssr: true,
      pageTitle: 'Residency Hub',
    },
    component: ResidencyHub,
    props: true,
  },
  {
    path: '/legal/',
    name: 'legal',
    component: GuestLegal,
    meta: {
      header: true,
      guest: true,
      ssr: true,
      pageTitle: 'Legal',
      robots: 'noindex',
    },
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: TermsConditions,
    meta: {
      header: true,
      guest: true,
      ssr: true,
      pageTitle: 'Terms and Conditions of Use',
      robots: 'noindex',
      since: 'RLF 2022-08-23',
    },
  },
  {
    path: '/purchase-policy',
    name: 'purchase-policy',
    component: PurchasePolicy,
    meta: {
      header: true,
      guest: true,
      ssr: true,
      pageTitle: 'Purchase Policy',
      robots: 'noindex',
      since: 'RLF 2022-10-11',
    },
  },
]
