import { defineStore } from 'pinia'

import * as api from '@/api/api'
import { StorePerformance, parsePerformance } from '@/models'
import { useReservationStore } from '@/modules/reservation/stores/ReservationStore'
import { useSettingStore } from '@/stores/setting'
import { byId, currentTime, deleteById, gtmEvt, todayIsoStr, upsertById } from '@/utils/utils'

import {
  AccountingReportRead,
  PerformanceCreate,
  PerformanceListRead,
  PerformanceQueryParams,
  PerformanceRead,
  PerformanceReportRead,
  PerformanceReservationsRead,
  PerformanceSeatingTimeWrite,
  PerformanceWrite,
  ShowId,
} from '@generated/types'

export interface PerformanceState {
  performances: StorePerformance[]
}

export const usePerformanceStore = defineStore({
  id: 'performance',
  state: (): PerformanceState => {
    if (typeof window === 'object' && window?.__tt_preload?.performance) {
      return window.__tt_preload.performance
    } else {
      return {
        performances: [],
      }
    }
  },
  getters: {
    performanceById() {
      return (id: number): StorePerformance => {
        let performance = this.performances.find(byId(id))
        if (!performance) {
          throw new Error(`Performance ${id} is not found`)
        }
        return performance
      }
    },
    maxPerformanceDate(): string {
      return [...new Set(this.performances.map(p => p.date))].sort().pop() || '1800-01-01'
    },
  },
  actions: {
    storePerformance(rawPerformance: PerformanceRead | PerformanceListRead): StorePerformance {
      const performance = parsePerformance(rawPerformance)
      return upsertById(performance, this.performances)
    },

    async getPerformances(query: PerformanceQueryParams) {
      const response = await api.getPerformances(query || {})

      response.data.results.map(this.storePerformance)

      return {
        total: response.data.count,
        pageSize: response.data.pageSize,
        performances: response.data.results,
      }
    },

    async getPerformanceReports(query: PerformanceQueryParams): Promise<PerformanceReportRead[]> {
      const response = await api.getPerformanceReports(query || {})

      return response.data
    },

    async getAccountingSummary(
      month: string,
      type: 'accrual' | 'cash',
    ): Promise<AccountingReportRead[]> {
      const response = await api.getAccountingSummary(month, type)
      return response.data
    },

    async getPerformance(id: number): Promise<StorePerformance> {
      const response = await api.getPerformance(id)
      return this.storePerformance(response.data)
    },

    async createPerformance(performance: PerformanceCreate) {
      gtmEvt('createPerformance')
      const response = await api.addPerformance(performance)
      return this.storePerformance(response.data)
    },

    async updatePerformance({
      id,
      date,
      time,
      show_id,
      seating_times,
      publish_date,
      publish_time,
    }: {
      id?: number
      date?: string
      time?: string
      show_id?: ShowId
      seating_times?: PerformanceSeatingTimeWrite[]
      publish_date?: string
      publish_time?: string
    }) {
      gtmEvt('updatePerformance')
      const performanceW: PerformanceWrite = {
        id,
        datetime: useSettingStore().makeDateTime({ time, date }),
        show_id,
        seating_times,
        publish_datetime: useSettingStore().makeDateTime({
          date: publish_date || todayIsoStr(),
          time: publish_time || currentTime(),
        }),
      }

      const response = await api.updatePerformance(performanceW)
      return this.storePerformance(response.data)
    },

    async deletePerformance(id: number) {
      gtmEvt('deletePerformance')
      await api.deletePerformance(id)
      deleteById(id, this.performances)
    },

    async getPerformanceReservations(id: number): Promise<PerformanceReservationsRead> {
      const response = await api.getPerformanceReservations(id)
      const performance = response.data
      this.storePerformance({
        id: performance.id,
        datetime: performance.datetime,
        publish_datetime: performance.publish_datetime,
        on_sale_datetime: performance.on_sale_datetime,
        show_id: performance.show.id,
        show: performance.show,
        floor_plan_id: performance.floor_plan_id,
        tables: performance.tables,
        sold: performance.sold,
      })
      if (response.data.reservations) {
        const reservationStore = useReservationStore()
        response.data.reservations.map(reservationStore.storeReservation)
      }

      return { ...response.data }
    },
  },
})
