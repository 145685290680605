<template>
  <nav>
    <div
      class="head flex justify-between items-center p-8 bg-header-primary border-b border-solid border-header-line"
    >
      <a
        class="logo w-logo"
        target="_blank"
        :href="settingStore.link_url"
        :aria-label="`Go to ${settingStore.venue_name} Website`"
      >
        <img :src="settingStore.logo_url" :alt="settingStore.venue_name" />
      </a>
      <ul class="xs:flex space-x-4 uppercase font-nav text-nav text-header-link">
        <li class="header-link block hover:duration-300 hover:text-header-link-hover">
          <router-link :to="{ name: 'setShow' }">Shows</router-link>
        </li>
        <li class="header-link block hover:duration-300 hover:text-header-link-hover">
          <router-link :to="{ name: 'calendar' }">Calendar</router-link>
        </li>
        <li
          v-if="isLoggedIn"
          class="header-link block hover:duration-300 hover:text-header-link-hover"
        >
          <a href="/venue/performance-new/0/reservations/"> Venue Tools </a>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script lang="ts">
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import { PERFORMANCE_ROUTE_NAMES } from '@/modules/performance/constants/PerformanceRouteConstants'
import { PERFORMANCE_PARAMS } from '@/modules/performance/stores/PerformanceReservationStore'
import { useSettingStore } from '@/stores/setting'

export default defineComponent({
  name: 'GuestHeader',
  async mounted() {
    await this.settingStore.loading
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useSettingStore),
    PERFORMANCE_PARAMS() {
      return PERFORMANCE_PARAMS
    },
    PERFORMANCE_ROUTE_NAMES() {
      return PERFORMANCE_ROUTE_NAMES
    },
  },
})
</script>
