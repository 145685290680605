/// <reference types="vite/client" />
import '../tailwind/input-guest.css'
import { RouterPlugin } from './router/RouterPlugin'
import { guestRoutes } from './router/guest-routes'
import { useSettingStore } from './stores/setting'
import { gtmPageView } from './utils/utils'
import 'external-svg-loader'
import { createPinia, setActivePinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import App from '@/App.vue'
import { configureBrowserAxios } from '@/api/api'
import { mkBrowserSentry } from '@/sentry'

configureBrowserAxios()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const router = createRouter({
  routes: guestRoutes,
  history: createWebHistory(),
})
router.afterEach(gtmPageView)

pinia.use(RouterPlugin(router))
setActivePinia(pinia)

const app = createApp(App)
app.use(pinia)
app.use(router)

if (import.meta.env.VUE_APP_VERSION) {
  mkBrowserSentry(app, router)
}
useSettingStore().fetch()
router.isReady().then(() => app.mount('#app'))
