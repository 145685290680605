<template>
  <div v-if="initialValue !== undefined" class="flex justify-start items-baseline gap-3">
    <UpdateIndicator :status="indicatorStatus" class="inline">
      <LabelDefault :forInput="forInput" class="label-default">
        <slot></slot>
      </LabelDefault>
    </UpdateIndicator>
    <ArrowReturnLeftIcon
      v-show="hasUpdated"
      class="return-left-icon w-3 h-3 text-fg-secondary cursor-pointer"
      @click="reset"
    />
  </div>
  <LabelDefault :forInput="forInput" v-else class="label-default">
    <slot></slot>
  </LabelDefault>
</template>

<script setup lang="ts">
import * as _ from 'lodash-es'
import { computed, toRefs } from 'vue'

import ArrowReturnLeftIcon from '@/assets/arrowReturnLeft.svg?component'
import LabelDefault from '@/common/components/atoms/LabelDefault.vue'
import UpdateIndicator from '@/common/components/atoms/UpdateIndicator.vue'

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: 'update:modelValue', initialValue: any): void
}>()

const props = withDefaults(
  defineProps<{
    initialValue: any
    modelValue: any
    forInput?: string
  }>(),
  {
    forInput: '',
  },
)
// initialValue needs to be reactive
// in the case of performances, once is updated, initial value should be updated as well
// just need to make sure initialValue comes from performanceReservationStore
// and modelValue from performanceUpdateStore
const { modelValue, initialValue } = toRefs(props)

const hasUpdated = computed(() => {
  return !_.isEqual(initialValue.value, modelValue.value)
})
const indicatorStatus = computed(() => {
  return hasUpdated.value ? 'updated' : 'none'
})

function reset() {
  emit('update:modelValue', _.cloneDeep(initialValue.value))
}
</script>
