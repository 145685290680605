<template>
  <label
    class="flex items-center xs:max-w-fit text-label cursor-pointer"
    :class="{ 'opacity-60 cursor-not-allowed': disabled }"
  >
    <div class="relative">
      <input
        class="flex items-center appearance-none w-5 h-5 border-2 border-fg-g-primary rounded cursor-pointer"
        :class="
          guest
            ? 'checked:bg-fg-g-primary bg-body-primary'
            : 'checked:bg-button-primary bg-surface-base'
        "
        type="checkbox"
        @change="onChange"
        :checked="modelValue"
        :disabled="disabled"
        :id="inputId"
        :aria-checked="modelValue"
        role="checkbox"
        :aria-label="label || 'checkbox'"
      />
      <CheckMark
        v-show="modelValue"
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 text-fg-g-primary invert !stroke-2"
      />
    </div>
    <div class="ml-1 font-label text-fg-secondary guest-ui:text-fg-disclaimer uppercase text-xs">
      {{ label }}
    </div>
  </label>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import CheckMark from '@/assets/check.svg?component'

export default defineComponent({
  name: 'CheckboxInput',
  components: {
    CheckMark,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    guest: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    inputId: {
      type: String,
      required: false,
    },
  },
  emits: {
    // eslint-disable-next-line no-unused-vars
    'update:modelValue': (_value: boolean) => true,
  },
  methods: {
    onChange(e) {
      this.$emit('update:modelValue', e.target.checked)
    },
  },
})
</script>
