import { defineStore } from 'pinia'

import * as api from '@/api/api'
import { ReservationCreateResponse } from '@/api/manual_types'
import { StoreReservation, parseReservation } from '@/models'
import { byId, defaultNumberOfGuests, deleteById, gtmEvt, upsertById } from '@/utils/utils'

import {
  PerformanceId,
  PerformanceReservationsRead,
  ReservationCreate,
  ReservationDetailedRead,
  ReservationId,
  ReservationRead,
  ReservationStatus,
  ReservationStatusType,
  ReservationUpdate,
} from '@generated/types'

export interface ReservationState {
  reservations: any[]
}

export const useReservationStore = defineStore({
  id: 'reservation',
  state: (): ReservationState => {
    return {
      reservations: [],
    }
  },
  getters: {
    reservationById() {
      return (id: number): StoreReservation => {
        let reservation = this.reservations.find(byId(id))
        if (!reservation) {
          throw new Error(`Reservation ${id} is not found`)
        }
        return reservation
      }
    },
  },
  actions: {
    storeReservation(rawReservation: ReservationRead): StoreReservation {
      const reservation = parseReservation(rawReservation)
      upsertById(reservation, this.reservations)
      return reservation
    },

    async getReservations() {
      const response = await api.getReservations()
      response.data.results.map(this.storeReservation)
    },

    async getReservation(id: number) {
      const response = await api.getReservation(id)
      return this.storeReservation(response.data)
    },

    async getReservationsByPerformance(performanceId: PerformanceId) {
      const response = await api.getReservationsByPerformance(performanceId)
      return response.data
    },

    async createReservation(reservation: ReservationCreate): Promise<ReservationCreateResponse> {
      gtmEvt('createReservation')
      const createResponse = await api.addReservation(reservation)
      return {
        client_secret: createResponse.data.client_secret,
        reservation: this.storeReservation(createResponse.data.reservation),
        error: createResponse.data.error,
      }
    },

    async seatWalkIn(
      performance: PerformanceReservationsRead,
      tableIds: number[],
      numberOfGuests?: number,
    ): Promise<ReservationCreateResponse> {
      const tables = performance.tables.filter(t => tableIds.includes(t.id))
      return await this.createReservation({
        status: ReservationStatus.SEATED,
        skip_guest: true,
        performance: performance.id,
        tables: tableIds,
        seating_time: performance.seating_times[0].id,
        number_of_guests: numberOfGuests || defaultNumberOfGuests(tables),
        guest_notes: '',
        internal_notes: '',
      })
    },

    async updateReservation(reservation: ReservationUpdate): Promise<ReservationCreateResponse> {
      gtmEvt('updateReservation')
      const updateResponse = await api.updateReservation(reservation)
      return {
        client_secret: '',
        reservation: this.storeReservation(updateResponse.data),
      }
    },

    async setReservationStatus(
      reservation: ReservationRead | ReservationDetailedRead,
      status: ReservationStatusType,
    ) {
      return this.updateReservation({
        id: reservation.id,
        status: status,
        seating_time: reservation.seating_time_id,
        tables: reservation.tables,
        number_of_guests: reservation.number_of_guests,
        guest_notes: reservation.guest_notes,
        internal_notes: reservation.internal_notes,
      })
    },

    async deleteReservation(id: number) {
      gtmEvt('deleteReservation')
      await api.deleteReservation(id)
      deleteById(id, this.reservations)
    },

    async abandonReservation(id: number) {
      gtmEvt('abandonReservation')
      await api.abandonReservation(id)
    },

    async refundTransaction(transactionId: number, amount: number) {
      gtmEvt('refundTransaction')
      await api.refundTransaction({ transactionId, amount })
    },

    async resendConfirmationEmail(id: number, email: string) {
      gtmEvt('resendConfirmationEmail')
      await api.resendConfirmationEmail(id, email)
    },

    async checkReservationPaymentStatus(id: ReservationId) {
      const { data: reservation } = await api.getReservationStatus(id)
      return upsertById(reservation, this.reservations)
    },
  },
})
