<template>
  <div class="relative">
    <svg
      viewBox="0 0 10 10"
      class="absolute w-1.5 h-1.5 top-0 left-0 -translate-x-full translate-y-1 -ml-1"
    >
      <circle r="5" cx="5" cy="5" :class="indicatorFill" />
    </svg>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'

import {
  UPDATE_INDICATOR_STATUS,
  UpdateIndicatorStatus,
} from '@/modules/performance/interfaces/PerformanceTableInterfaces'

const props = defineProps<{
  status: UpdateIndicatorStatus
}>()
const { status } = toRefs(props)

const indicatorFill = computed(() => {
  switch (status.value) {
    case UPDATE_INDICATOR_STATUS.NEW:
      return 'fill-status-seated'
    case UPDATE_INDICATOR_STATUS.UPDATED:
      return 'fill-status-noshow'
    default:
      return 'fill-transparent'
  }
})
</script>
