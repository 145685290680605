export type PerformanceRouteNames =
  (typeof PERFORMANCE_ROUTE_NAMES)[keyof typeof PERFORMANCE_ROUTE_NAMES]

export const PERFORMANCE_FLOW_ROUTES = {
  SCHEDULE: 'performanceSchedule',
  TABLES: 'performanceTables',
  TABLE_EDIT: 'performanceTableEdit',
  SEATING_TIMES: 'performanceSeatingTimes',
  ADD_ONS: 'performanceAddOns',
  ADD_ON_EDIT: 'performanceAddOnEdit',
  PRICING_TIERS: 'performancePricingTiers',
  DASHBOARD: 'performanceDashboard',
  LIVESTREAM: 'performanceLivestream',
  MISCELLANEOUS: 'performanceMiscellaneous',
  PRINT_RESERVATIONS: 'performancePrintReservations',
}
export const PERFORMANCE_RESERVATION_ROUTES = {
  RESERVATION_LIST: 'performanceReservationList',
  RESERVATION_WALK_IN: 'performanceReservationWalkIn',
  RESERVATION_DETAIL: 'performanceReservationDetail',
  RESERVATION_EDIT: 'performanceReservationEdit',
  RESERVATION_ADD_ON_LIST: 'performanceReservationAddOnList',
  RESERVATION_MOVE_DETAIL: 'performanceReservationMoveDetail',
  RESERVATION_REFUND: 'performanceReservationRefund',
}

export const PERFORMANCE_ROUTE_NAMES = {
  ...PERFORMANCE_FLOW_ROUTES,
  ...PERFORMANCE_RESERVATION_ROUTES,
}
