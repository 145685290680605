<template>
  <section v-if="display" class="h-full tt-spinner">
    <div class="p-8 flex justify-center">
      <div
        style="border-top-color: transparent"
        class="w-8 h-8 border-4 border-fg-link-primary border-double rounded-full animate-spin loading-spinner"
      ></div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Spinner',
  props: {
    display: { type: Boolean, default: true },
  },
})
</script>
<style scoped>
.guest-ui .tt-spinner div {
  border-color: var(--primary-theme-color);
}
</style>
