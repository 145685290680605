import { useSettingStore } from '@/stores/setting'
import { byId, round2, sortByMultiple, sum } from '@/utils/utils'

import {
  PerformanceAddOnRead,
  PerformanceTableRead,
  ReservationAddOnRead,
  ReservationAddOnWrite,
  ReservationTableDetailedRead,
} from '@generated/types'

export interface EnrichedTable extends PerformanceTableRead {
  description: string
  guests: number
  price_per_person: number
  type: string
}

export interface PriceableAddOn {
  price: number
  quantity: number
  lineTaxCents: number
}

export interface EnrichedAddOn extends PerformanceAddOnRead {
  lineAmountCents: number
  lineTaxCents: number
  quantity: number
}

export interface PricingOptions {
  tables: EnrichedTable[]
  addOns: PriceableAddOn[]
  numberOfGuests: number
  serviceFeePerGuest: number
  servicePercentage: number
  taxRate: number
  feeTaxRate: number
  percentOff: number
  applicationFee: number
  applicationFeePerReservation: number
  previousTables?: ReservationTableDetailedRead[]
  previousFee?: number
}

export interface OrderPricing {
  amount: number
  addOnAmount: number
  tax: number
  serviceFee: number
  discounted: number
  ticketTax: number
  feeTax: number
  turntableFee: number
  stripeFee: number
  netSale: number
  total: number
}

export function seatTablesWithRemainder(
  tableReads: PerformanceTableRead[],
  guestsRemaining: number,
): { tables: EnrichedTable[]; guestsRemaining: number } {
  let sortedTables = sortByMultiple([...tableReads], ['-price_per_person', '-maximum_guests'])
  let tables: EnrichedTable[] = []
  for (const table of sortedTables) {
    const seated = Math.min(guestsRemaining, table.maximum_guests)
    const tableType = useSettingStore().tableType(table.type_id).name || 'Table'
    tables.push({
      ...table,
      description: !table.standing_room ? `${tableType} (${table.name})` : `${table.name}`,
      guests: seated,
      price_per_person: +table.price_per_person || 0,
      type: tableType,
    })
    guestsRemaining = guestsRemaining - seated
  }
  return { tables, guestsRemaining }
}

export function seatTablesToMax(
  tableReads: PerformanceTableRead[],
  guests: number,
): EnrichedTable[] {
  const { tables, guestsRemaining } = seatTablesWithRemainder(tableReads, guests)
  if (guestsRemaining > 0) {
    // We have more guests than tables, this should maybe be an error condition
  }
  return tables
}

export function seatTables(tableReads: PerformanceTableRead[], guests: number) {
  const { tables, guestsRemaining } = seatTablesWithRemainder(tableReads, guests)
  if (guestsRemaining > 0 && tables.length > 0) {
    tables[0].guests += guestsRemaining
  }
  return tables
}

export function tablesForSeatedParty(
  performance: { tables?: PerformanceTableRead[] } | null,
  tableIds: number[],
  guestsRemaining: number,
): EnrichedTable[] {
  if (!performance?.tables) return []
  const tableReads = tableIds.flatMap(id => performance.tables!.find(byId(id)) || []) // O(n^2) because we want to preserve order
  return seatTablesToMax(tableReads, guestsRemaining)
}

export function priceAddOns(
  performanceAddOns: PerformanceAddOnRead[] | null,
  addOns: ReservationAddOnWrite[] | null,
): EnrichedAddOn[] {
  if (!addOns?.length || !performanceAddOns?.length) return []
  return performanceAddOns
    .map(pAddOn => {
      const rAddOn = addOns.find(addOn => addOn.performance_add_on_id === pAddOn.id) || {
        quantity: 0,
      }
      const lineAmountCents = rAddOn.quantity * pAddOn.price * 100
      const lineTaxCents = (lineAmountCents * pAddOn.tax_rate) / 100
      return { ...pAddOn, lineAmountCents, lineTaxCents, quantity: rAddOn.quantity }
    })
    .filter(addOn => addOn.quantity > 0)
}

export function repriceAddOns(
  addOns: ReservationAddOnRead[],
  performanceAddOns: PerformanceAddOnRead[],
): PriceableAddOn[] {
  return addOns.map(addon => {
    const performanceAddOn = performanceAddOns.find(byId(addon.performance_add_on_id))
    const lineAmountCents = addon.quantity * addon.price * 100
    const lineTaxCents = (lineAmountCents * performanceAddOn!.tax_rate) / 100
    return { ...addon, lineAmountCents, lineTaxCents }
  })
}

export function getZeroPricing(): OrderPricing {
  return {
    amount: 0,
    addOnAmount: 0,
    tax: 0,
    serviceFee: 0,
    discounted: 0,
    ticketTax: 0,
    feeTax: 0,
    turntableFee: 0,
    stripeFee: 0,
    netSale: 0,
    total: 0,
  }
}

export function getAmount(tables: EnrichedTable[], previousTables: ReservationTableDetailedRead[]) {
  const newCost = sum(tables.map(t => t.price_per_person * t.guests) || 0)
  // We see if the table was seated in the previous reservation, if so we adjust the amount
  // so the guest pays the original price on the table
  const adjustment = sum(
    (previousTables || []).map(pt => {
      const table = tables.find(x => x.id === pt.performancetable_id)
      if (!table || !pt.price_per_person || !pt.guests) return 0
      return (pt.price_per_person - table.price_per_person) * pt.guests
    }),
  )
  return newCost + adjustment
}

function discount(x: number, percentOff: number): number {
  return x * (1 - percentOff / 100)
}

export function getPricing({
  tables,
  addOns,
  numberOfGuests,
  serviceFeePerGuest,
  servicePercentage,
  taxRate,
  feeTaxRate,
  percentOff,
  applicationFee,
  applicationFeePerReservation,
  previousTables = [],
  previousFee = 0,
}: PricingOptions): OrderPricing {
  const fullAmount = round2(getAmount(tables, previousTables))

  const addOnAmount = round2(sum(addOns.map(a => a.price * a.quantity)) || 0)
  const addOnTax = sum(addOns.map(a => a.lineTaxCents)) / 100
  const newGuests = Math.max(0, numberOfGuests - sum(previousTables.map(t => t.guests || 0)))
  const previousAmount = sum(previousTables.map(t => (t.price_per_person || 0) * (t.guests || 0)))
  const fullServiceFee = round2(
    ((fullAmount - previousAmount) * servicePercentage) / 100 +
      serviceFeePerGuest * newGuests +
      previousFee,
  )
  const amount = round2(discount(fullAmount, percentOff))
  const serviceFee = round2(discount(fullServiceFee, percentOff))
  if (amount + addOnAmount == 0 || amount + serviceFee < 0.5) {
    return getZeroPricing()
  }
  const tax = round2((taxRate * amount + feeTaxRate * serviceFee) / 100 + addOnTax)
  const discounted = round2(fullAmount + fullServiceFee - amount - serviceFee) || 0
  const turntableFee = numberOfGuests * (applicationFee / 100) + applicationFeePerReservation / 100
  const stripeFee = amount * 0.029 + 0.3
  const total = round2(amount + addOnAmount + tax + serviceFee)
  const netSale = total - stripeFee - turntableFee

  return {
    amount,
    addOnAmount,
    tax,
    serviceFee,
    discounted,
    ticketTax: amount * (taxRate / 100),
    feeTax: serviceFee * (feeTaxRate / 100),
    turntableFee,
    stripeFee,
    netSale,
    total,
  }
}

export function pricingTotal(reservation: {
  amount: number | string
  service_fee: number | string
  tax: number | string
  discounted: number | string
  add_on_amount: number | string
}): number {
  return (
    Number(reservation.amount || 0) +
    Number(reservation.service_fee || 0) +
    Number(reservation.tax || 0) +
    Number(reservation.discounted || 0) +
    Number(reservation.add_on_amount || 0)
  )
}

export function toReservationParams(pricing: OrderPricing) {
  return {
    amount: pricing.amount.toFixed(2),
    service_fee: pricing.serviceFee.toFixed(2),
    tax: pricing.tax.toFixed(2),
    discounted: pricing.discounted.toFixed(2),
    add_on_amount: pricing.addOnAmount.toFixed(2),
  }
}
