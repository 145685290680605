import {
  ExtraErrorData as ExtraErrorDataIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations'
import * as Sentry from '@sentry/vue'
import { Router } from 'vue-router'

export function mkSentry(app, router: Router, integrations = []) {
  const environment = import.meta.env.VUE_APP_VERSION.startsWith('main') ? 'prod' : 'dev'
  Sentry.init({
    app,
    dsn: 'https://bd69456c2b9f49cda174d529eb895d4a@o1281109.ingest.sentry.io/6655619',
    environment,
    release: import.meta.env.VUE_APP_VERSION,
    integrations,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    tracesSampleRate: 0.01,
  })
}

export function mkBrowserSentry(app, router: Router) {
  const integrations = [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        /\w+.turntabletickets.com\//,
        'tickets.jazztx.com/',
        'livestreams.smokejazz.com',
        'tickets.blackcatsf.com',
        'tickets.christospizzasalem.com',
        'tickets.monksjazz.com',
        'tickets.smokejazz.com',
      ],
    }),
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 3,
    }),
    new ReportingObserverIntegration({
      types: ['crash', 'deprecation', 'intervention'],
    }),
  ]
  return mkSentry(app, router, integrations)
}

export function mkServerSentry(app, router: Router) {
  const integrations = [
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 3,
    }),
  ]
  return mkSentry(app, router, integrations)
}
