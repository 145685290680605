<template>
  <g class="dot-grid">
    <g class="y-axis" v-for="yTick in yTicks" :key="yTick">
      <circle
        v-for="xTick in xTicks"
        :key="xTick"
        :cx="xTick"
        :cy="yTick"
        :r="1 / ratio"
        class="x-axis fill-linear-strong"
      />
    </g>
  </g>
</template>

<script setup lang="ts">
import { range } from 'lodash-es'
import { computed } from 'vue'

const props = defineProps<{
  columnCount: number
  svgHeight: number
  ratio: number
}>()

const xTicks = computed(() => range(0, props.columnCount + 1, 2))
const yTicks = computed(() => range(0, props.svgHeight + 1, 2))
</script>
