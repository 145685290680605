<template>
  <section :class="{ 'flex flex-col': !isCheckoutDesktopFlow }">
    <StatusBar v-if="!isCheckoutDesktopFlow" />
    <section :class="{ 'h-full grow px-4': !isCheckoutDesktopFlow }">
      <h1 class="text-2xl md:text-4xl px-4 font-bold font-heading">How many guests?</h1>

      <GuestSelection v-if="!isLoadingTable" @click:guest="setGuestCount" />
      <GuestSelectionSkeleton v-else />

      <div id="max-guests-warning" class="text-right font-sans">
        More than {{ max_guests }}? Please
        <a id="contact-us-link" class="text-primary underline" :href="`mailto:${reply_to}`">
          contact us</a
        >.
      </div>
      <div class="mt-2 px-4" :class="isCheckoutDesktopFlow ? 'mt-96' : 'mt-auto pb-10'">
        <ButtonDefault
          id="back-button"
          label="Back"
          class="font-semibold uppercase text-sm px-4 py-1.5 border border-solid border-button-guest-primary rounded font-button text-button-guest-primary"
          guest
          @click="onBack"
        />
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import ButtonDefault from '@/common/components/atoms/ButtonDefault.vue'
import GuestSelectionSkeleton from '@/modules/guestUi/components/molecules/GuestSelectionSkeleton.vue'
import GuestSelection from '@/modules/guestUi/components/organisms/GuestSelection.vue'
import StatusBar from '@/modules/guestUi/components/organisms/StatusBar.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { blankError } from '@/utils/errors'

export default defineComponent({
  name: 'SetGuests',
  components: {
    GuestSelectionSkeleton,
    GuestSelection,
    ButtonDefault,
    StatusBar,
  },
  data() {
    return {
      guestCount: 0,
      prevRoute: null,
      isLoadingTable: true,
      ui: {
        error: blankError(),
      },
    }
  },

  beforeRouteEnter(_, from, next) {
    next(vm => {
      // @ts-ignore
      vm.prevRoute = from
    })
  },

  async mounted() {
    this.checkoutStore.clearNumberOfGuests()
    await this.checkoutStore.refreshPerformance()
    this.isLoadingTable = false
  },

  computed: {
    ...mapStores(useCheckoutStore),
    ...mapState(useSettingStore, ['max_guests', 'reply_to']),

    isCheckoutDesktopFlow(): boolean {
      return this.checkoutStore.isDesktopFlow
    },
  },

  methods: {
    async setGuestCount(count: number) {
      await this.checkoutStore.setNumberOfGuests(Number(count), this.ui.error)
    },

    onBack() {
      history.go(-1)
    },
  },
})
</script>
