<template>
  <g
    class="room-table"
    :transform="`translate(${localX}, ${localY}) rotate(${rotation})`"
    ref="roomTableRef"
  >
    <!--SHAPE SELECTION-->
    <!-- Invisible Interactive Layer -->
    <rect
      v-if="shape === TableShape.SQUARE"
      :x="-width"
      :y="-height"
      :width="width * 2"
      :height="height * 2"
      v-bind="propsInvisibleShape"
      class="square-invisible"
    />
    <!-- Visible Shape -->
    <rect
      v-if="shape === TableShape.SQUARE"
      rx="0.3"
      ry="0.3"
      :x="-width"
      :y="-height"
      :width="width * 2"
      :height="height * 2"
      v-bind="propsVisibleShape"
      class="square"
    />

    <!-- Invisible Interactive Layer -->
    <circle
      v-if="shape === TableShape.CIRCLE"
      :cx="0"
      :cy="0"
      :r="width"
      v-bind="propsInvisibleShape"
      class="circle-invisible"
    />
    <!-- Visible Shape -->
    <circle
      v-if="shape === TableShape.CIRCLE"
      :cx="0"
      :cy="0"
      :r="width"
      v-bind="propsVisibleShape"
      class="circle"
    />

    <!-- Invisible Interactive Layer -->
    <path
      v-if="shape === TableShape.HALF_CIRCLE"
      :d="`M-${width},-${height / 2} a1,1 0 0,0 ${width * 2},0`"
      v-bind="propsInvisibleShape"
      class="half-circle-invisible"
    />
    <!-- Visible Shape -->
    <path
      v-if="shape === TableShape.HALF_CIRCLE"
      :d="`M-${width},-${height / 2} a1,1 0 0,0 ${width * 2},0`"
      v-bind="propsVisibleShape"
      class="half-circle"
    />

    <!-- Invisible Interactive Layer -->
    <polygon
      v-if="shape === TableShape.TRIANGLE"
      :points="trianglePoints"
      v-bind="propsInvisibleShape"
      class="triangle-invisible"
    />
    <!-- Visible Shape -->
    <polygon
      v-if="shape === TableShape.TRIANGLE"
      :points="trianglePoints"
      v-bind="propsVisibleShape"
      class="triangle"
    />

    <PianoShape
      v-if="shape === TableShape.PIANO"
      :width="width"
      :height="height"
      :class="[strokeClass, fillClass, 'piano']"
    />

    <!--LABEL CONTENT SELECTION-->
    <g v-if="marker === 'xmark'" class="xmark">
      <line class="line-1 stroke-white" v-bind="xmarkLeftLine" :stroke-width="2 / venueScale" />
      <line class="line-2 stroke-white" v-bind="xmarkRightLine" :stroke-width="2 / venueScale" />
    </g>
    <text
      v-else-if="marker === 'checkmark'"
      x="0"
      y="0"
      dominant-baseline="middle"
      text-anchor="middle"
      class="checkmark"
      :class="[textFillClass || 'fill-white']"
      style="font-size: 1.3px"
      :transform="`rotate(${-rotation})`"
    >
      &#10003;
    </text>
    <text
      v-else-if="!truncate"
      x="0"
      y="0"
      dominant-baseline="middle"
      text-anchor="middle"
      :class="[textFillClass, 'label']"
      style="font-size: 1.3px"
      :transform="`rotate(${-rotation})`"
    >
      <slot />
    </text>
    <foreignObject v-else :x="-width" :y="-height" :width="width * 2" :height="height * 2">
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        :class="[textFillClass, 'label flex items-center justify-center text-center w-full h-full']"
        style="font-size: 1.3px"
        :style="{ padding: `0 ${1 / aspectRatio}px` }"
      >
        <slot />
      </div>
    </foreignObject>
  </g>
</template>

<script lang="ts" setup>
import { useDraggable } from '@vueuse/core'
import * as _ from 'lodash-es'
import { storeToRefs } from 'pinia'
import { ComputedRef, SVGAttributes, computed, inject, ref, toRefs, watch } from 'vue'

import PianoShape from '@/common/components/atoms/PianoShape.vue'
import { useSettingStore } from '@/stores/setting'
import { ROOM_TABLE_RELATIVE_WIDTH } from '@/utils/utils'

import { PerformanceTableRead, TableShape, TableShapeType } from '@generated/types'

export type Marker = 'checkmark' | 'xmark' | 'none'
export interface RoomTableProps {
  shape: TableShapeType
  width: number
  height: number
  x: number
  y: number
  rotation?: number
  marker?: Marker
  fillClass?: string
  strokeClass?: string
  textFillClass?: string
  draggable?: boolean
  focusable?: boolean
  isFocused?: boolean
  ariaLabel?: string
  truncate?: boolean
}

const props = withDefaults(defineProps<RoomTableProps>(), {
  rotation: 0,
  marker: 'none',
  fillClass: 'fill-surface-table-available',
  strokeClass: 'stroke-transparent',
  textFillClass: 'fill-fg-table-available',
  draggable: false,
  focusable: false,
  isFocused: false,
  ariaLabel: '',
  truncate: false,
})
const { width, height, shape, draggable, strokeClass, fillClass, focusable, isFocused, ariaLabel } =
  toRefs(props)
const { ratio: aspectRatio } = storeToRefs(useSettingStore())

const trianglePoints = computed<string>(function () {
  return `${-width.value * 1.5},${height.value * 0.5} ${width.value * 0.5},${height.value * 0.5} ${
    width.value * 0.5
  },${-height.value * 1.5} ${-width.value * 1.5},${height.value * 0.5}`
})
const xmarkMargin = computed<number>(function () {
  switch (shape.value) {
    case TableShape.CIRCLE:
      return width.value * 0.4
    case TableShape.HALF_CIRCLE:
      return width.value * 0.7
    default:
      return 0.6
  }
})
const xmarkLeftLine = computed<SVGAttributes>(function () {
  if (shape.value === TableShape.CIRCLE)
    return {
      x1: -width.value + xmarkMargin.value,
      y1: -width.value + xmarkMargin.value,
      x2: width.value - xmarkMargin.value,
      y2: width.value - xmarkMargin.value,
    }

  return {
    x1: -width.value + xmarkMargin.value,
    y1: -height.value + xmarkMargin.value,
    x2: width.value - xmarkMargin.value,
    y2: height.value - xmarkMargin.value,
  }
})
const xmarkRightLine = computed<SVGAttributes>(function () {
  if (shape.value === TableShape.CIRCLE)
    return {
      x1: -width.value + xmarkMargin.value,
      y1: width.value - xmarkMargin.value,
      x2: width.value - xmarkMargin.value,
      y2: -width.value + xmarkMargin.value,
    }

  return {
    x1: -width.value + xmarkMargin.value,
    y1: height.value - xmarkMargin.value,
    x2: width.value - xmarkMargin.value,
    y2: -height.value + xmarkMargin.value,
  }
})

// D R A G G I N G    F U N C T I O N A L I T Y
const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (event: 'update:position', values: Pick<PerformanceTableRead, 'x' | 'y'>): void
}>()
const localX = ref(props.x)
const localY = ref(props.y)
watch([() => props.x, () => props.y], ([x, y]) => {
  localX.value = x
  localY.value = y
})
let mouseToCenterOffset: { xDistance: number; yDistance: number } = { xDistance: 0, yDistance: 0 }
const roomTableRef = ref<SVGGraphicsElement | null>(null)
const svgElementRef = inject('svgElementRef') as ComputedRef<SVGElement | null>
const venueRoomPaddingX = inject('paddingX') as ComputedRef<number>
const venueScale = inject('venueScale') as ComputedRef<number>
useDraggable(roomTableRef, {
  draggingElement: svgElementRef,
  containerElement: svgElementRef,
  onStart(_, { offsetX, offsetY }) {
    if (!draggable.value) return
    const { x, y } = getMouseRelativePosition(offsetX, offsetY)
    mouseToCenterOffset = { xDistance: x - localX.value, yDistance: y - localY.value }
  },
  onMove(_, { offsetX, offsetY }) {
    if (!draggable.value) return
    const { x, y } = getTableRelativePosition(offsetX, offsetY)
    localX.value = x
    localY.value = y
  },
  onEnd(_, { offsetX, offsetY }) {
    if (!draggable.value) return
    const { x, y } = getTableRelativePosition(offsetX, offsetY)
    emit('update:position', { x: Math.round(x), y: Math.round(y) })
  },
})
function getMouseRelativePosition(offsetX: number, offsetY: number): { x: number; y: number } {
  return {
    x: (offsetX - venueRoomPaddingX.value) / venueScale.value,
    y: offsetY / venueScale.value,
  }
}
function getTableRelativePosition(offsetX: number, offsetY: number): { x: number; y: number } {
  const svgRelativeHeight = ROOM_TABLE_RELATIVE_WIDTH / aspectRatio.value
  const { x: relativeMouseX, y: relativeMouseY } = getMouseRelativePosition(offsetX, offsetY)
  return {
    x: _.clamp(relativeMouseX - mouseToCenterOffset.xDistance, 0, ROOM_TABLE_RELATIVE_WIDTH),
    y: _.clamp(relativeMouseY - mouseToCenterOffset.yDistance, 0, svgRelativeHeight),
  }
}

const STROKE_WIDTH = 2
const propsVisibleShape = computed<SVGAttributes>(() => ({
  'stroke-width': STROKE_WIDTH / venueScale.value,
  'stroke-linejoin': 'round',
  class: [strokeClass.value, fillClass.value],
  ...(focusable.value && {
    role: 'radio',
    tabindex: '-1',
    'aria-checked': false,
    'aria-label': ariaLabel.value,
  }),
}))

const STROKE_TARGET_AREA = 6
const propsInvisibleShape = computed<SVGAttributes>(() => ({
  'stroke-width': STROKE_TARGET_AREA / venueScale.value,
  'stroke-linejoin': 'round',
  class: focusable.value && isFocused.value ? 'stroke-fg-g-primary' : 'stroke-transparent',
  fill: 'transparent',
}))
</script>
