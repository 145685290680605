<template>
  <section>
    <div class="flex">
      <div
        :id="`quantity-selector-${id}-decrement`"
        @click="stepUpDown(-1)"
        class="bg-base-secondary w-12 cursor-pointer rounded text-center font-semibold"
      >
        -
      </div>
      <div>
        <input
          :id="`quantity-selector-${id}-value`"
          ref="quantityValue"
          type="number"
          min="0"
          max="99"
          step="1"
          class="bg-transparent w-10 text-center"
          @change="onChange"
          :value="modelValue"
        />
      </div>
      <div
        :id="`quantity-selector-${id}-increment`"
        @click="stepUpDown(1)"
        class="bg-base-secondary w-12 cursor-pointer rounded text-center font-semibold"
      >
        +
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'QuantitySelector',
  props: {
    id: { type: Number, required: true },
    modelValue: { type: Number, required: true },
  },
  emits: {
    'update:modelValue'(evt: number) {
      return !!evt
    },
  },
  methods: {
    onChange(e) {
      this.$emit('update:modelValue', Number(e.target.value))
    },
    stepUpDown(increment: number): void {
      const quantityInput = this.$refs?.quantityValue as HTMLInputElement
      increment > 0 ? quantityInput.stepUp() : quantityInput.stepDown()
      quantityInput.dispatchEvent(new Event('change'))
    },
  },
})
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
}
</style>
