<template>
  <section class="px-4 max-w-screen-xl mx-auto pb-10 pt-10">
    <h1 class="text-3xl font-bold font-heading mb-8">
      {{ guest.first_name }}, Your Order Is Confirmed!
    </h1>
    <div class="flex flex-col lg:flex-row lg:space-x-20">
      <div class="w-full lg:w-2/5">
        <div class="flex space-x-4 mb-6">
          <img
            :src="currentShow.image"
            :alt="currentShow.name"
            class="rounded-md w-20 h-20 object-cover border border-linear-strong"
          />
          <div>
            <h2 class="font-heading font-semibold text-lg">{{ currentShow.name }}</h2>
            <span class="font-sans" id="formattedDateTime">
              <span id="performanceDate" class="font-sans inline">{{ performanceDate }}</span>
              <span id="showtime" class="block">{{ showtime }}</span>
            </span>
          </div>
        </div>

        <div id="numberOfGuests">{{ numberOfGuests }} Guests</div>
        <div id="seatingTimeDisplay" class="mb-4">{{ seatingTime }} Seating Time</div>

        <CheckoutDetailTable />

        <div class="font-sans my-8">
          We received your order and sent an email confirmation to {{ guest.email }}.
        </div>
        <div>Thank you!</div>
        <div>{{ venueName }}</div>
      </div>

      <div class="w-full lg:w-3/5 mt-12 flex justify-end">
        <RoomMap id="room-map" :backgroundMode="roomMapBgMode">
          <RoomTable
            v-for="table in tables"
            :key="table.id"
            :height="table.h"
            :width="table.w"
            :rotation="table.rotation"
            :shape="table.shape"
            :x="table.x"
            :y="table.y"
            :marker="backendReservation.tables.includes(table.id) ? 'checkmark' : 'none'"
            :fillClass="
              backendReservation.tables.includes(table.id) ? 'fill-primary' : 'fill-table-available'
            "
          />
        </RoomMap>
      </div>
    </div>
    <div class="mt-8 text-button-guest-primary">
      <router-link :to="{ name: 'setShow' }"> Return to shows. </router-link>
    </div>
  </section>
</template>

<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import RoomTable from '@/common/components/molecules/RoomTable.vue'
import RoomMap from '@/common/components/organisms/RoomMap.vue'
import { ROOM_MAP_BG_MODE, RoomMapBgMode } from '@/common/interfaces/RoomMap'
import { StorePerformance, StoreReservation, StoreShow } from '@/models'
import CheckoutDetailTable from '@/modules/guestUi/components/organisms/CheckoutDetailTable.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

import { GuestRead, PerformanceTableRead } from '@generated/types'

export default defineComponent({
  name: 'OrderDetails',
  components: {
    CheckoutDetailTable,
    RoomTable,
    RoomMap,
  },
  data() {
    return {
      roomMapBgMode:
        getComputedStyle(document.documentElement).getPropertyValue('--mode') ||
        (ROOM_MAP_BG_MODE.FORCE_LIGHT as RoomMapBgMode),
    }
  },
  beforeMount() {
    if (!this.checkoutStore.reservation || !this.checkoutStore.performance) {
      this.$router.push('/')
    }
  },
  beforeRouteLeave(to, _, next) {
    if (to.name === 'setShow') next()
    else next({ name: 'setShow' })
  },
  computed: {
    ...mapStores(useCheckoutStore, useSettingStore),
    ...mapState(useSettingStore, ['currency']),

    backendReservation(): StoreReservation {
      if (!this.checkoutStore.reservation) {
        throw new Error('No valid reservation is present')
      }
      return this.checkoutStore.reservation
    },

    guest(): GuestRead {
      if (!this.backendReservation.contact) {
        throw new Error('No valid guest is present')
      }
      return this.backendReservation.contact
    },

    performance(): StorePerformance {
      if (!this.checkoutStore.performance) {
        throw new Error('No valid performance is present')
      }
      return this.checkoutStore.performance
    },

    currentShow(): StoreShow {
      return this.performance.show
    },

    numberOfGuests(): number {
      return this.backendReservation.number_of_guests
    },

    seatingTime(): string {
      return this.checkoutStore.seating_time?.time || ''
    },

    performanceDate(): string {
      return `${this.settingStore.fullDate(this.performance.date)}`
    },
    showtime(): string {
      return `${this.settingStore.timeStr(this.performance.datetime)} Showtime`
    },

    venueName(): string {
      return this.settingStore.venue_name
    },
    tables(): PerformanceTableRead[] {
      return this.performance.tables || []
    },
  },
})
</script>
