<template>
  <div class="sm:mb-4 md:mb-10">
    <slot name="monthTitle">
      <div
        id="monthTitle"
        class="w-full text-xl text-center font-heading font-semibold uppercase md:text-3xl"
      >
        {{ monthTitle }}
      </div>
    </slot>
    <div
      class="flex md:border-b md:border-linear-strong md:guest-ui:border-linear-g-primary"
      :class="{ 'flex-wrap': !showPerformanceInMobile }"
    >
      <div
        v-for="label in weekLabels"
        :key="`weeklabel-${label}`"
        class="flex-1 text-sm text-center font-medium py-2 px-3 text-fg-tertiary md:border-r md:last:border-none md:border-linear-strong md:guest-ui:border-linear-g-primary md:text-left"
      >
        {{ label }}
      </div>
    </div>
    <div
      v-for="week in weeks"
      :key="week.key"
      class="flex"
      :class="{ 'flex-wrap': !showPerformanceInMobile }"
    >
      <CalendarDay
        :class="calendarDayClasses"
        v-for="date in week.days"
        :key="date.date"
        :date="date"
        :isSelected="date.date === selectedDate"
        :isToday="date.date === today"
        :inMonth="date.date.startsWith(datePrefix)"
        :showPerformanceInMobile="showPerformanceInMobile"
        @click="clickDay(date)"
        @click:performance="clickDay(date, $event)"
      />
      <div class="show-details w-full" v-if="!hideShowDetails">
        <div v-if="week.selected">
          <template v-if="showIds.length > 0">
            <CalendarShowDetails
              v-for="showId in showIds"
              :key="`${showId}-${selectedDate}`"
              :showId="showId"
              :date="selectedDate"
              :performances="performances"
            />
          </template>
          <template v-else>
            <CalendarShowDetails
              :key="`no-show-for-${selectedDate}`"
              :showId="-1"
              :date="selectedDate"
              :performances="performances"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import * as _ from 'lodash-es'
import moment, { Moment } from 'moment'
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import CalendarDay, { CalendarDayModel } from '@/components/calendar/CalendarDay.vue'
import CalendarShowDetails from '@/components/calendar/CalendarShowDetails.vue'
import { StorePerformance } from '@/models'
import { useSettingStore } from '@/stores/setting'
import { sortBy } from '@/utils/utils'

import { ShowId } from '@generated/types'

interface CalendarWeek {
  selected: boolean
  key: string
  days: CalendarDayModel[]
}

export default defineComponent({
  name: 'CalendarMonth',
  components: { CalendarShowDetails, CalendarDay },
  props: {
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    selectedDate: {
      type: String,
      required: true,
    },
    calendarDayClasses: {
      type: String,
      default: '',
    },
    hideShowDetails: {
      type: Boolean,
      default: false,
    },
    omitWeeksBeforeToday: {
      type: Boolean,
      default: false,
    },
    showPerformanceInMobile: {
      type: Boolean,
      default: false,
    },
    performances: {
      type: Array as () => StorePerformance[],
      required: true,
    },
  },

  computed: {
    ...mapStores(useSettingStore),

    currentDetailsComponent() {
      return 'CalendarShowDetails'
    },

    weekLabels(): string[] {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    },

    datePrefix(): string {
      let month = this.month < 10 ? `0${this.month}` : this.month
      return `${this.year}-${month}`
    },

    firstOfTheMonth(): Moment {
      return moment(`${this.datePrefix}-01`)
    },

    monthTitle(): string {
      return this.firstOfTheMonth.format('MMMM')
    },

    startDay(): Moment {
      let today = moment()
      if (
        this.omitWeeksBeforeToday &&
        this.year == today.year() &&
        this.month == today.month() + 1
      ) {
        return today.startOf('week')
      }
      return this.firstOfTheMonth.clone().startOf('week')
    },

    endDay(): Moment {
      return this.firstOfTheMonth.clone().endOf('month').endOf('week')
    },

    weeks(): CalendarWeek[] {
      return this.calendarDays.reduce((results: CalendarWeek[], item, index) => {
        const weekIndex = Math.floor(index / 7)

        if (!results[weekIndex]) {
          results[weekIndex] = {
            selected: false,
            days: [],
            key: `week-${item.date}`,
          }
        }
        if (item.date === this.selectedDate) {
          results[weekIndex].selected = this.selectedDate.startsWith(this.datePrefix)
        }
        results[weekIndex].days.push(item)

        return results
      }, [])
    },

    today(): string {
      return moment().format('yyyy-MM-DD')
    },

    showIds(): ShowId[] {
      const target: StorePerformance[] =
        this.performances.filter(
          performance =>
            moment(performance.date).format('yyyy-MM-DD') ===
            moment(this.selectedDate).format('yyyy-MM-DD'),
        ) || []

      return _.uniq(sortBy(target, 'datetime').map(p => p.show.id))
    },

    calendarDays(): CalendarDayModel[] {
      const days: CalendarDayModel[] = []
      const date: Moment = this.startDay.clone().subtract(1, 'day')

      while (this.endDay.isAfter(date, 'day')) {
        const momentDay: Moment = date.add(1, 'day').clone()

        const target: StorePerformance[] =
          this.performances.filter(
            performance =>
              moment(performance.date).format('yyyy-MM-DD') ===
              moment(momentDay).format('yyyy-MM-DD'),
          ) || []

        days.push({
          date: momentDay.format('yyyy-MM-DD'),
          day: momentDay.format('D'),
          month: momentDay.format('MMMM'),
          year: momentDay.format('yyyy'),
          performances: sortBy(target, 'datetime'),
        })
      }
      return days
    },
  },
  methods: {
    clickDay(date: CalendarDayModel, performance?: StorePerformance) {
      if (!date.date.startsWith(this.datePrefix)) return
      if (performance) {
        this.$emit('click:performance', performance)
      } else {
        this.$emit('click:date', date.date)
      }
    },
  },
  emits: {
    // eslint-disable-next-line no-unused-vars
    'click:date': (date: string) => true,
    // eslint-disable-next-line no-unused-vars
    'click:performance': (performance: StorePerformance) => true,
  },
})
</script>
