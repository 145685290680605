<template>
  <div class="font-sans text-fg-error text-base">
    {{ displayMessage }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ErrorDisplay',
  props: {
    code: { type: String, required: true },
    message: { type: String, required: true },
  },
  computed: {
    displayMessage(): string {
      return this.message
    },
  },
})
</script>
